/**
 * Collection [_variables]
 */
$collection-padding : 10px;
$img-height         : 200px;

/**
 * Collection [_Definition]
 */
.collection{
    background-color : $panel-bg;
    border-radius    : $panel-border-radius;
    @include box-shadow($material-shadow-1dp);
    @include transition(all 0.25s);
    &:hover{
        @include box-shadow($material-shadow-4dp);
    }

    .collection-header{
        text-align : center;
        padding    : $collection-padding;
        .collection-img{
            height        : $img-height;
            margin-bottom : $collection-padding;
            a{
                height          : $img-height;
                width           : 100%;
                display         : flex;
                flex-direction  : column;
                justify-content : center;
                align-items     : center;
                img{
                    max-width  : 100%;
                    max-height : $img-height;
                }
            }
        }
        .collection-title{
            line-height : $line-height-base;
            font-size   : ceil(($font-size-base * 1.125));
            color       : inherit;
            margin      : 0;
        }
    }
    .collection-body{
        border-top: 1px solid $brand-border;
        @include clearfix;
        .span-center{
            display         : flex;
            justify-content : space-around;
            span{
                width      : 50%;
                text-align : center;
                padding    : ($collection-padding * 1.2) 0;
                &:last-child{
                    border-left: 1px solid $brand-border;
                }
            }
        }
    }
    .collection-footer{
        border-top: 1px solid $brand-border;
        .footer-column{
            width      : 50%;
            text-align : center;
            float      : left;
            padding    : ($collection-padding * 1.2) 0;
            span{
                line-height: 35px;
                white-space: nowrap;
                overflow: hidden;
                display: block;
                padding: 0 10px;
                text-overflow: ellipsis;
            }
            &:last-child{
                border-left: 1px solid $brand-border;
            }
        }
        .btn{
            padding     : 10px 12px;
            line-height : 1;
            min-height  : inherit;
            box-shadow  : none;
        }
    }
}
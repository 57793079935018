/**
 * Definición de variables.
 */
$panel_padding: 10px;

/**
 * Definición de .panel-card [Información del la práctica].
 */
.panel-card{
	.panel-heading{
		border  : 0;
		padding : $panel_padding;
		.panel-title{
			font-weight : 500;
			font-size   : 17px;
			margin      : 20px 0 0 0;
			line-height : 1.476;
		}
	}
	.panel-body{
		padding: $panel_padding;
		.text-info{

		}
	}
	.panel-footer{
		padding: $panel_padding;
		.counter-users{
			margin-bottom : $panel_padding;
			font-weight   : 700;
    		span{
				display   : block;
				font-size : 15px;
    		}
		}
	}
}

/**
 * Definición de .panel-solution [Collapse de la solución de la práctica].
 */
.panel-solution{
	.table, .images-group{
		margin-bottom: 15px;
	}
	.img-container{
		img{
			max-width  : 177px;
			max-height : 177px;
		}
	}
}
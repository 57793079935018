/**
 * Panel Collection [_Definition]
 */
.panel-collection{
    .panel-body{
        text-align : left;
        padding    : 10px;
        h3{
            font-weight : 500;
            font-size   : 17px;
            margin      : ($base-computed * 0.80) 0;
            line-height : ($base-line * 0.80);
        }
        p{
            word-wrap     : break-word;
            margin-bottom : ($base-computed * 0.80);
        }
        .list-group{
            margin-bottom: 0;
        }
        .form-container{
            margin-top: ($base-computed * 0.80);
        }
        @media (max-width: $screen-md) {

            text-align: center;
        }
    }
}
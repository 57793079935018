/**
 * Center container [_Definition]
 */
.center-container{
    margin: ($navbar-height / 2) auto;
}

.center-container-md{
    margin: $navbar-height auto;
}

.alert-container-scrollable{
	margin: 50px auto 20px
}

.center-image{
	position : relative;
	overflow : hidden;
	display  : inline-block;
	img{
		/*
		display  : inline;
		margin   : auto;
		position : absolute;
		left     : 50%;
		top      : 50%;
		height   : 100%;
		width    : auto;
        @include translate(-50%,-50%);
		*/
        height: 100%;
        width: 100%;
        object-fit: cover;
	}
}

.container-wrapper{
	max-width : $container-lg;
	@include container-fixed;
}


/**
 * column centered [_Definition]
 */
.col-centered{
    float   : none;
    display : inline-block;
}

/**
 * Loading [_Definition]
 */
.loading{
    text-align: center;
    padding: 5%;
}

/**
 * Send Form [_Definition]
 */
#sending, #save{
    display: none;
}

/**
 * Vertical Align Center
 */
.valign-table-container{
	display : table;
	height  : 100%;
	width   : 100%;
    .valign-center{
    	/* To center vertically */
		display        : table-cell;
		vertical-align : middle;
    }
}
.valign-ccontent{
	width  :inherit;
	height :inherit;
	margin : 0 auto;
}
/**
 *
 * Botones de redes sociales.
 *
 * @url http://designpieces.com/2012/12/social-media-colours-hex-and-rgb/
 */
$bg_facebook : #3b5998;
$bg_twitter  : #55acee;
$bg_google   : #dd4b39;
$bg_linkedin : #007bb5;
$bg_mail     : #00bf8f;
/*
.btn-facebook{
	background: $bg_facebook;
}
.btn-twitter{
	background: $bg_twitter;
}
.btn-google{
	background: $bg_google;
}
.btn-linkedin{
	background: $bg_linkedin;
}
.btn-mail{
	background: $bg_mail;
}
*/
/**
 * Panel Quiz [_Definition]
 */
.panel-quiz{
	@media (max-width: $screen-md) {

        text-align: center;
    }
	.form-publish{
		text-align : center;
		margin     : ($line-height-computed / 2) 0;
	}
	.quiz-link{
		margin-top    : 20px;
		margin-bottom : 0;
	}
}

.container-img-question{
	img{
		max-width: 177px;
	}
}
/**
 * Question Collapse [_Definition]
 *
 * @url: https://codepen.io/tofanelli/pen/waadRY
 */
.question-group{
	margin-bottom: 0;
	.panel-heading{
		padding: 0;
		.panel-title{
			a{
				padding         : 15px;
				display         : block;
				text-decoration : none;
				&:hover{
					color           : $brand-primary;
					text-decoration : none;
				}
				&:after{
					display   : inline-block;
					font      : normal normal normal 14px/1 FontAwesome;
					content   : "\f0d7";
					position  : absolute;
					font-size : 16px;
					right     : 15px;
					top       : 19px;
				}
			}
			.collapsed:after {
			    content: "\f0da";
			}
		}
	}
	.alert{
		margin      : 0;
		text-align  : center;
		padding     : 7px;
		line-height : 27px;
		i{
			margin: 0;
		}
	}
}

/**
 * Tabla de las alternativas [_Definition]
 */
.table-alternatives-group {
    > thead,
  	> tbody,
  	> tfoot {
    	> tr {
	      	> th,
	      	> td{
        		vertical-align: middle;
        		&:first-child{
        			width: 62px;
        		}
      		}
    	}
  	}
	pre{
		margin: 0 !important;
	}
}

/**
 * Grupo de imágenes [_Definition]
 */
.images-group{
	text-align: center;
	.panel-item{
		.panel{
			margin-bottom: 15px;
		}
		&:nth-child(n+4){
			.panel{
				margin-bottom: 0px;
			}
		}
	}
	.panel-body{
		padding: 10px;
		.img-container{
			position        : relative;
			min-height      : 128px;
			max-height      : 128px;
			display         : flex;
			align-items     : center;
			justify-content : center;
			img{
				max-width      : 100%;
				max-height     : 128px;
				vertical-align : middle;
			}
		}
	}
	.panel-footer{
		padding    : 5px;
		border-top : 1px solid $brand-border !important;
	}
}
/**
 * Main navbar [_definition]
 */
.main-navbar{
    background: $brand-primary;
    .breadcrumb-container{
        width: 100%;
        h2{
            font-size   : 20px;
            font-weight : 400;
            color       : rgb(255, 255, 255);
            @media (max-width: $screen-xs) {

                line-height: 28px;
            }
        }
    }
    .btn-container{
        width: 100%;
        text-align: right;
    }
    .breadcrumb {

        > li,
        a {
            color: rgb(248, 250, 252);
            @media (max-width: $screen-xs) {

                line-height: 28px;
            }
        }

        > .active {
            color: rgb(200,205,230);
        }
    }
    @media (max-width: $screen-sm) {

        .breadcrumb-container{
            text-align: center;
        }
        .btn-container{
            text-align    : center;
            margin-bottom : $line-height-computed;
        }

    }
}

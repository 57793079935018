.md-elevation {
    border   : 0 !important;
    position : relative;
    z-index  : 1;
}

.md-elevation-1dp {
    @include box-shadow($material-shadow-1dp);
}

.md-elevation-2dp {
    @include box-shadow($material-shadow-2dp);
}

.md-elevation-3dp {
    @include box-shadow($material-shadow-3dp);
}

.md-elevation-4dp {
    @include box-shadow($material-shadow-4dp);
}

.md-elevation-5dp {
    @include box-shadow($material-shadow-5dp);
}

.md-elevation-6dp {
    @include box-shadow($material-shadow-6dp);
}

.md-elevation-7dp {
    @include box-shadow($material-shadow-7dp);
}

.md-elevation-8dp {
    @include box-shadow($material-shadow-8dp);
}

.md-elevation-9dp {
    @include box-shadow($material-shadow-9dp);
}

.md-elevation-10dp {
    @include box-shadow($material-shadow-10dp);
}

.md-elevation-11dp {
    @include box-shadow($material-shadow-11dp);
}

.md-elevation-12dp {
    @include box-shadow($material-shadow-12dp);
}

.md-elevation-13dp {
    @include box-shadow($material-shadow-13dp);
}

.md-elevation-14dp {
    @include box-shadow($material-shadow-14dp);
}

.md-elevation-15dp {
    @include box-shadow($material-shadow-15dp);
}

.md-elevation-16dp {
    @include box-shadow($material-shadow-16dp);
}

.md-elevation-17dp {
    @include box-shadow($material-shadow-17dp);
}

.md-elevation-18dp {
    @include box-shadow($material-shadow-18dp);
}

.md-elevation-19dp {
    @include box-shadow($material-shadow-19dp);
}

.md-elevation-20dp {
    @include box-shadow($material-shadow-20dp);
}

.md-elevation-21dp {
    @include box-shadow($material-shadow-21dp);
}

.md-elevation-22dp {
    @include box-shadow($material-shadow-22dp);
}

.md-elevation-23dp {
    @include box-shadow($material-shadow-23dp);
}

.md-elevation-24dp {
    @include box-shadow($material-shadow-24dp);
}

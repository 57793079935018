/**
 * Ranking [Definition]
 */
.main-ranking{
	.image-column{
		padding: $table-cell-padding 0;
		a{
			width: 40px;
			height: 40px;
		}
	}
	.pagination{
		margin: 0;
	}
	.form-inline{
		.btn{
			max-height: 38px;
		}
	}
}

.table-ranking{
  	> thead > tr > th {
    	color: rgba(rgb(0,0,0), .8);
    }
	> tbody > tr > td {
        padding: 20px 8px;
  	}
}
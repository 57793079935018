/**
 * Margins [_definition]
 */
.mbottom-z0{
	margin-bottom: 0 !important;;
}
.mbottom-p{
	margin-bottom: ($line-height-computed / 2) !important;;
}
.mbottom-z1{
	margin-bottom: 15px !important;;
}
.mbottom-z2{
	margin-bottom: 30px !important;;
}
.mbottom-z35{
	margin-bottom: 35px !important;;
}
.m-equals-p{
	margin: ($line-height-computed / 2) 0 !important;;
}
.pagination-margin{
	.pagination{
		margin: 20px 0 0 0;
	}
}
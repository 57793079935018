/**
 * Main Welcome [_definition]
 */
.main-welcome{
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    min-height: 100%;
    background-image: url(/img/landing.jpg);
    background-position: 0px 0px;
    min-height: 600px;
    @media (max-width: $screen-sm) {
    	min-height: 700px;
    }
    @media (max-width: $screen-xs) {
    	min-height: 400px;
    }
    .welcome-content{
    	background: rgba(57, 73, 175,.85);
	    position: absolute;
	    top: 0;
	    width: 100%;
	    left: 0;
	    height: 100%;
	    padding: 100px 0;
	    text-align: center;
	    color: white;
	    display: flex;
	    .center-content{
			justify-content: center;
			display: flex;
			flex-direction: column;
	    }
	    h2{
			font-size : 54px;
			font-weight: 700;
			letter-spacing: .2px;
			margin-bottom: 40px;
			text-shadow: 0 1px 2px rgba(0,0,0,.7);
			@media (max-width: $screen-sm) {
		    	font-size : 48px;
		    }
			@media (max-width: $screen-xs) {
	    	    font-size: 36px;
		    }
		}
		p{
			color         : $brand-text-light;
			font-size     : 20px;
			@media (max-width: $screen-sm) {
		    	font-size : 18px;
		    }
			@media (max-width: $screen-xs) {
		    	font-size : 16px;
		    }
		}
		.counters{
			margin: 50px 0;
			@media (max-width: $screen-xs) {
		    	display: none;
		    }
			.counter-item{
				display   : inline-block;
				margin    : 0 25px;
				color     : $brand-text-light;
				font-size : 16px;
				strong{
					color: rgb(255, 255, 255);
				}
			}
		}
    }
}

.homepage-section{
	.section-header{
		position      : relative;
	    margin-bottom: 30px;
    	text-align: center;
		h2{
			font-size      : 28px;
			text-transform : uppercase;
			margin: 0 0 30px 0;
			font-weight    : 700;
			display        : inline-block;
			position       : relative;
			/*
			&:before{
				position   : absolute;
				bottom     : -10px;
				left       : 0;
				height     : 2px;
				width      : 100%;
				content    : '';
				background : black;
			}
			*/
		}
		p{
		    font-size: 14px;
		}
	}
	.section-body{

		@media (max-width: $screen-sm) {

    		.vertical-align{
    			display: block;
    			.intro-img{
				    margin-bottom: 40px;
    			}
    		}
  		}

	}

}

.main-intro{
    padding: 120px 0;
    background: white;
    .col-intro{
    	padding: 0 40px;
    	@media (max-width: $screen-sm) {
    		padding: 15px;
    	}
    }
	.intro-layer{
		.intro-header{
			h2{
		        font-size: 28px;
			    text-transform: uppercase;
			    font-weight: 700;
			    margin: 0 0 30px;
			    text-align: center;
			    text-shadow: 0 1px 1px rgba(255,255,255,.7);
			}
		}
		.intro-body{
			p{
				font-size: 14px;
			    text-align: justify;
			    line-height: 24px;
		        margin-bottom: 20px;
		        color: rgb(80,80,80);
		        &:last-child{
		        	margin-bottom: 0;
		        }
			}
		}
	}
}

.main-info{
    padding: 60px 0;
    background: white;
	@media (max-width: $screen-sm) {

	.vertical-align{
		display: block;
		.info-message{
	        margin-bottom: 20px;
		}
	}
	}
	.info-message{
		h5{
			font-weight: 500;
		    font-size: 16px;
		    line-height: 28px;
		    text-transform: uppercase;
		    text-align: center;
		}
	}
	.info-btns{
		.btn{
			padding: 0 20px;
		}
		.btn-default{
			color: $brand-primary;
			margin-left: 20px;
			&:hover,
			&:active{
				background-color: transparent;
			}
		}
	}
}

/**
 * Main Collection [_definition]
 */
.main-collection{
	padding: $navbar-height 0;
	.collection-body{

	}
	.more-container{
		margin-top: 60px;
    	text-align: center;
	}
}

/**
 * Home Element [_variables]
 */
$img-height: 240px;
/**
 * Home Element [_definition]
 */
.main-card{
	display    : flex;
	flex       : 1 100%;
	flex-flow  : column nowrap;
	margin-top : 30px;
	background : white;
	@include transition(all .15s);

	.card-btn-float{
		opacity: 0;
	    position: absolute;
	    top: 10px;
	    right: 10px;
	    @include transition(all .15s);
	    .btn{
    	    font-size: 10px;
		    text-transform: uppercase;
		    font-weight: 500;
	    }
	}

	&:hover{
		@include box-shadow($material-shadow-4dp);
		.card-btn-float{
			opacity: 1;
		}
	}
/*
    .img-container{
		height : $img-height;
		width  : 100%;
    	.img-card{
			width               : 100%;
			height              : $img-height;
			display             : block;
			position            : relative;
			background-size     : cover;
			background-position : center bottom;
			background-repeat   : no-repeat;
    	}
    }
    .img-block{
		max-width  : $img-height;
		max-height : $img-height;
        &:hover{

        }
    }
*/
    .img-container{
		height          : $img-height;
		width           : 100%;
		display         : flex;
		flex-direction  : column;
		justify-content : center;
		align-items     : center;
    	.img-card{
			max-width  : 100%;
			max-height : $img-height;
    	}
    }
    .card-body{
		align-items     : center;
		justify-content : space-around;
		display         : flex;
		flex            : 1 100%;
		flex-flow       : column nowrap;
		padding         :10px;
		text-align      : center;
	    h3{
			font-size   : 16px;
			margin      : 15px 0;
			font-weight : 500;
			line-height : 1.5;
    	}
    	p{
    		margin-bottom: 0;
    	}
    }
    .card-footer{
    	border-top: 1px solid $brand-border;
    	.pull-left{
			width        : 50%;
			border-right : 1px solid $brand-border;
			text-align   : center;
			padding      : 10px 0;
    	}
    	.pull-right{
			text-align : center;
			width      : 50%;
			padding    : 10px 0;
    	}
    	.footer-bottom{
		    border-top: 1px solid $brand-border;
    		padding: 10px;
    	}
    }
}

.collections-wrapper{
	@media (max-width: $screen-lg) {

		.row-flex{
		    flex-direction: column;
		}

	}
}

.collection-card{
	width: 100%;
	background: white;
    margin-top: 30px;
    @include box-shadow($material-shadow-1dp);
    @include transition(all .15s);
    &:hover{
    	@include box-shadow($material-shadow-4dp);
    }
	.body-card{
		padding: 10px;
		display: flex;
		@media (max-width: $screen-tablet) {
		    display: inline-block;
    		text-align: center;
		    width: 100%;
		}
		.card-image{
			position: relative;
			.image-container{
				a{
					img{
						width: 177px;
						height: 177px;
					    min-width: 177px;
						object-fit: cover;
					}
				}
			}
			.image-counter{
			    width: 60px;
			    height: 60px;
			    background: $brand-primary;
			    position: absolute;
			    bottom: 0;
			    color: white;
			    padding: 10px;
			    font-size: 12px;
			    text-align: center;
			    p{
			    	margin: 0;
			    }
			}
		}
		.card-content{
			width: 100%;
    		padding: 0 10px;
			h3{
				font-size   : 15px;
				margin      : 0 0 10px 0;
				font-weight : 500;
				line-height : 24px;
			}
			p{
				text-align: justify;
			}
			@media (max-width: $screen-tablet) {
				p{
					text-align: center;
				}
			}
			.content-actions{
				.action-item{
					float: left;
    				line-height: 35px;
				}
				.action-btns{
					float: right;
				}
			}
			.content-user{
				.user-img{
				    float: left;
			        .center-image{
			        	width: 32px;
				    	height: 32px;
			        	border-radius: 50%;
			        }
				}
				.user-text{
				    float: left;
				    margin-left: 10px;
				    line-height: 32px;
				    color: rgb(100,100,100);

				    white-space: nowrap;
				    overflow: hidden;
				    text-overflow: ellipsis;
				    width: calc(100% - 42px);
				    a{
				    	//color: inherit;
				    	//text-decoration: underline;
				    }
				}
				@media (max-width: $screen-tablet) {
			        display: flex;
    				justify-content: center;
    				.user-img{
    					float: none;
    				}
    				.user-text{
    					width: auto;
    				}
				}
			}
		}
	}
	.footer-card{
		border-top: 1px solid $brand-border;
		.footer-flex{
		    display: flex;
		    padding: 10px;
		    flex-direction: row;
			.col-medium{
			    width: 50%;
			    text-align: center;
			}
			.col-medium-1{
				border-right: 1px solid $brand-border;
			}
		}
	}
}
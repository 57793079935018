// Center-align a block level element

@mixin center-block() {
	display: block;
	margin-left: auto;
	margin-right: auto;
}
/**
 * Perfect center (horizontal / vertical) using Flexbox
 */
@mixin center-flexbox() {
	display: flex;
	align-items: center;
	justify-content: center;
}
/**
 * Align content using flexbox.
 */
.center-block{
	display: flex;
	align-items: center;
	justify-content: center;
}
.row-valign{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    > div[class*='col-']{
        display: flex;
        align-items: center;
    }
}
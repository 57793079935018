/**
 * Error form [Definition]
 */
.error{
	color       : $brand-error;
	font-weight : 400;
}
/**
 * Página de Error [Definition]
 */
.page-error{
	max-width  : 560px;
	margin     : 5% auto;
	text-align : center;
	padding    : 20px;
	.error-code{
		font-size : 128px;
		margin    : 0;
	}
	.error-title{
		font-size   : 24px;
		margin      : 20px 0;
		line-height : 36px;
	}
	.error-message{
        font-size: 15px;
    	margin-bottom: 20px;
	}
	.error-navigation{
		ul{
			padding : 0;
			margin  : 0;
			li{
			    display: inline-block;
				a{
					position   : relative;
					min-width  : 100px;
					display    : block;
					text-align : center;
				}
			}
		}
	}
}
/**
 * Breadcrumb collections [Definition]
 */
.evaluation-header{
	.evaluation-title{
		margin-bottom: 23px;
	    line-height: 30px;
	    text-align: center;
	    color: white;
	    text-shadow: 0 1px 1px rgba(0,0,0,.2);
	    font-size: 16px;
	    span{
	    	font-weight: 500;
	    }
	}
	.comments{
	    float: right;
	    font-size: $font-size-base;
	    font-weight: 400;
	    color: $text-color;
	}
}

.evaluation-container{
	//max-width: 700px;
    margin: 0 auto;
    padding: 20px;
    background: white;
    @include box-shadow($material-shadow-1dp);
}

.form-evaluation{
	.evaluation-header{
		margin-bottom: 20px;
	    border-bottom: 1px solid $brand-primary;
	    padding-bottom: 10px;
	    h3{
    	    font-size: 15px;
		    margin: 0;
		    color: $brand-primary;
	    }
	}
	.evaluation-body{
		.evaluation-question{
			img{
				max-width: 100%;
			}
		}
		.evaluation-alternatives{
		    margin: 20px 0;
		}
	}
	.evaluation-footer{
		.btn{
			min-height: initial;
			line-height: 24px;
		}
	}
}

.linetime-evaluation{
	border-top: 1px solid $brand-primary;;
    margin-top: 20px;
    padding-top: 20px;
	.pagination-evaluation{
		@media (max-width: $screen-sm) {
			text-align: center;
		}
		.question-list{
		    margin-left: -3px;
    		margin-right: -3px;
			.btn{
				line-height: 21px;
				border-radius: 50%;
				margin: 3px;
				color: rgb(120,120,120);
				border: 1px solid rgb(120,120,120);
				@include user-select(none);
				&:focus,
			    &.focus {
			      	outline: none !important;
			    }
			    width: 40px;
			    height: 40px;
			    font-size: 12px;
			    position: relative;
			    span{
					position: absolute;
					top: 0;
					width: 100%;
					left: 0;
					height: 100%;
					line-height: 38px;
			    }
			}
			.btn-primary{
				color            : $brand-primary !important;
				background-color : rgb(209,214,239) !important;
				border           : 1px solid $brand-primary !important;
			}
			.btn-default{

			}
		}
	}
	.terminate-evaluation{
		text-align: right;
		@media (max-width: $screen-sm) {
			text-align: center;
		}
		.btn-primary{
		    line-height: 24px;
			margin: 3px 0;
		}
	}
}

/*
.form-evaluation{
	.form-header{
		margin-bottom: $line-height-computed;
		h2{
			font-size     : 28px;
			font-weight   : 400;
			line-height   : 40px;
			margin-bottom : 30px;
			margin-top    : 0;
			color         : $brand-primary;
		}
		h4{
			color       : rgb(58,58,58);
			margin      : 0;
			font-weight : 400;
			font-size   : 20px;
			line-height : 32px;
		}
		.img-container{
			justify-content : center;
			display         : flex;
			align-items     : center;
			width           : 100%;
			img{
				//max-width : 144px;
				max-height  : 144px;
			}
		}
	}
	.form-alternatives{
		margin-bottom: $line-height-computed;
	}
	.form-footer{
		text-align: right;
		span.pull-left{
			margin-top  : 13px;
			font-weight : 400;
			font-size   : 14px;
			line-height : 24px;
		}
	}
}
*/

.list-alternatives{
    margin-bottom: 0;
	.list-group-item{
		background-color : transparent;
		padding          : 0;
		//margin-bottom    : 10px;
		border           : 1px solid $brand-border;
		@include transition(all .15s);
		//@include box-shadow(0 1px 2px rgba(0,0,0,0.15));
		.radio, .checkbox{
			margin: 0;
			> label{
				width      : 100%;
				background : white;
				padding    : 12px 12px 12px 40px;
			}
		}
		&:hover{
			//@include box-shadow(0 1px 2px rgba(0,0,0,0.20), 0 3px 5px rgba(0,0,0,0.15));
		}
		&:last-child{
			margin-bottom: 0px;
		}
	}
}

.code-alternatives{
    padding: 0;
    margin: 0;
    .list-group-item {
        padding: 0;
        margin-bottom: 10px;
        &:hover{
			@include box-shadow(0 1px 2px rgba(0,0,0,0.20), 0 3px 5px rgba(0,0,0,0.15));
		}
        .radio{
    	    width: 100%;
    		margin: 0;
        }
        label{
			width: 100%;
			margin: 0;
			padding: 10px;
			display: flex;
        }
        .input-container {
            width: 50px;
            padding: 0 10px 0 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1px solid $brand-border;
            input{
        	    margin: 0;
            }
        }
        .option-container {
            width: calc(100% - 50px);
            padding: 0 0 0 10px;
            pre {
                margin: 0;
                max-height: 300px;
            }
        }
    }
}

/*
.code-alternatives{
	text-align: center;
	.alternative-item{
		padding  : 15px 0;
		position : relative;
		label{
			margin-bottom : 0;
			width         : 100%;
			background    : white;
			padding       : 10px;
			text-align    : center;
			border        : 0;
			@include transition(all .15s);
			@include box-shadow(0 1px 2px rgba(0,0,0,0.15));
			> pre{
				min-height : 150px;
				max-height : 150px;
				text-align : left;
			}
			&:hover{
				@include box-shadow(0 3px 7px rgba(0,0,0,0.20), 0 3px 7px rgba(0,0,0,0.15));
			}
		}
	}
}
*/
.images-alternatives{
	text-align:center;
    display: flex;
    justify-content: center;
	.alternative-item{
		padding : 15px 0;
		width   : 100%;
	    display: flex;
    	flex: 1 100%;
    	flex-flow: column nowrap;
    	label{
			width         : 100%;
			border-radius : 2px;
			background    : #fff;
			@include transition(all .15s);
			@include box-shadow(0 1px 2px rgba(0,0,0,0.15));
			text-align : center;
			display    : flex;
			flex       : 1 100%;
			flex-flow  : column nowrap;
			.item-image{
				padding       : 15px;
				border-bottom : 1px solid $brand-border;
				background    : white;
				display: flex;
				flex: 1 100%;
				align-items: center;
				justify-content: center;
				> img{
					background : transparent url("/img/md-loader.gif") center center no-repeat;
					max-height : 180px;
					max-width: 100%;
				}
			}
			.item-radio{
				padding: 15px;
			}
			&:hover{
				@include box-shadow(0 5px 10px rgba(0,0,0,0.20), 0 5px 10px rgba(0,0,0,0.15));
			}
    	}
	}
}

.result-container{
	.result-heading{
		h2{
			font-size     : 20px;
			font-weight   : 500;
			//line-height : 40px;
			margin-bottom : $line-height-computed;
			margin-top    : 0;
			color         : $brand-primary;
		}
		h4{
			color         : rgb(28,28,28);
			margin        : 0 0 $line-height-computed 0;
			font-weight   : 400;
			font-size     : 15px;
			//line-height : 30px;
		}
	}
	.btn-container{
		margin-top: 40px;
	}
}

/**
 *
 * Block comment
 *
 */
.result-counter{
	.form-group{
		&:last-child{
			border-top    : 1px solid #ddd;
			padding-top   : 15px;
			margin-bottom : 0;
		}
	}
}

/**
 *
 * Evaluación [Instancia del evaluado]
 *
 */
.evaluation-detail{
    display: flex;
    flex-direction: row;
	.detail-image{
		display: flex;
        align-items: center;
        justify-content: center;
		> a{
		    width: 84px;
    		height: 84px;
		}
	}
	.detail-text{
	    margin-left: 15px;
	}
	@media (max-width: $screen-xs) {
		flex-direction: column;
		.detail-image{
			margin-bottom: 15px;
			> a {
			    width: 96px;
	    		height: 96px;
			}
		}
		.detail-text{
		    margin-left: 0;
		}
	}
}
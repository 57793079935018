/**
 * Loading [_Definition]
 */
.loaded-container{
    margin   : 50px 0;
    position : relative;
}

.img-loading{
    //min-height: 250px;
    background: transparent url("/img/md-loader.gif") center center no-repeat;
}

/**
 * Spinner Container [_Definition]
 */
.spinner-container{
    text-align: center;
    .spinner {
        width    : 74px;
        height   : 74px;
        position : relative;
        margin   : 100px auto;
    }
    .double-bounce1,
    .double-bounce2 {
        width            : 100%;
        height           : 100%;
        border-radius    : 50%;
        background-color : $brand-primary;
        opacity          : 0.6;
        position         : absolute;
        top              : 0;
        left             : 0;
        @include animation(sk-bounce 2.0s infinite ease-in-out);
    }

    .double-bounce2 {
        -webkit-animation-delay : -1.0s;
        animation-delay         : -1.0s;
    }

    @-webkit-keyframes sk-bounce {
        0%, 100% {
            @include scale(0.0)
        }

        50% {
            @include scale(1.0)
        }

    }

    @keyframes sk-bounce {
        0%, 100% {
            @include scale(0.0)
        }

        50% {
            @include scale(1.0)
        }

    }

}
/**
 * VueJs Transitions [_definition]
 */

/* always present */
.fade-transition {
    transition: opacity .25s ease;
}

/* .fade-enter defines the starting state for entering */
.fade-enter, .fade-leave {
    opacity: 0;
}

/* .fade-leave defines the ending state for leaving */
.fade-leave{
    display: none;
}
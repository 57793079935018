/**
 * Elevation panel information [Definition].
 */
.container-review{
	.review-alert{

	}
	.card-image{
		background: white;
		margin-bottom: $line-height-computed;
		.card-header{
			padding  : 10px;
			position : relative;
	        .header-img{
				position      : relative;
				text-align    : center;
				margin-bottom : 20px;
	        	img{
	        		max-height: 350px;
	        	}
	        	.header-float{
					position        : absolute;
					top             : 0;
					left            : 0;
					width           : 100%;
					height          : 100%;
					background      : rgba(0,0,0,.4);
					display         : flex;
					align-items     : center;
					justify-content : center;
					opacity         : 0;
				    @include transition(all .25s);
	        	}
	        	&:hover{
	        		.header-float{
	        			opacity: 1;
	        		}
	        	}
	        }
		    h1{
				font-size   : 19px;
				margin      : 0;
				font-weight : 500;
				margin      : 0;
		    }
		}
		.card-body{
		    padding: 10px;
		}
		.card-footer{
			background : white;
			padding    : 20px;
			text-align : center;
			ul{
				margin  : 0;
				padding : 0;
				li{
					list-style   : none;
					display      : inline-block;
					margin-right : 10px;
					a{
						border-radius : 3px;
						color         : rgb(80,80,80);
						min-height    : auto;
						border        : 1px solid $brand-border;
					    &:hover{
							border : 1px solid transparent;
							color  : white;
					    }
					}
					.btn-facebook{
						&:hover{
							background: $bg_facebook;
						}
					}
					.btn-twitter{
						&:hover{
							background: $bg_twitter;
						}
					}
					.btn-google{
						&:hover{
							background: $bg_google;
						}
					}
					.btn-linkedin{
						&:hover{
							background: $bg_linkedin;
						}
					}
					.btn-mail{
						&:hover{
							background: $bg_mail;
						}
					}
				}
			}
		}
	}
	.card-quiz{
		@media (max-width: $screen-md) {
			margin-top: 30px;
		}
		.card-body{
			h5{
				font-weight : 700;
				margin      : 0 0 20px 0;
				font-size   : 15px;
			}
			.btn-layer{
				text-align : center;
				margin-top : 30px;
				@media (max-width: $screen-xs) {
					margin-top : 20px;
					.btn{
						margin-top: 20px;
					}
				}
			}
		}
	}
}
/** Minicards details **/
.info-item{
	background      : white;
	padding         : 20px;
	width           : 100%;
	text-align      : center;
	justify-content : center;
	align-items     : center;
	display         : flex;
	flex-flow       : column nowrap;
	img{
		max-height    : 64px;
		margin-bottom : ($line-height-computed / 2) !important;;
	}
	p:last-child{
		margin-bottom: 0;
	}
}
.icon-item{
	background : white;
	padding    : 20px;
	text-align : center;
	margin-top : 30px;
	@include box-shadow($material-shadow-1dp);
	i{
	    font-size: 48px;
	}
	p:last-child{
		margin-bottom: 0;
	}
}
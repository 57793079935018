/**
 * Main Form [_Definition]
 */
.main-form{
    padding    : 23px 0;
    background : white;
    border-bottom: 1px solid rgba(0,0,0,.1);
    .form-container{
        width           : 100%;
        justify-content : flex-start;
        display         : flex;
        .form-inline{

        }
        .input-group{

        }
    }
    .form-information{
        text-align : right;
        width      : 100%;
        h4{
            font-size    : 13px;
            font-weight  : normal;
            display      : inline-block;
            margin-right : 10px;
        }
        .btn-pagination{
            display: inline-block;
        }
    }
    .pagination-information{
        text-align : right;
        width      : 100%;
        h4{
            font-size   : 13px;
            font-weight : normal;
            display     : inline-block;
        }
    }
    @media (max-width: $screen-md) {

        .form-container{
            margin-bottom : $line-height-computed;
            text-align    : center;
            .form-inline{
                width      : 100%;
                text-align : center;
            }
        }
        .form-information{
            text-align: center;
        }
        .pagination-information{
            text-align: center;
        }

    }
    @media (max-width: $screen-sm) {

        .form-container{
            .input-group{
                width         : 100%;
                margin-bottom : 15px;
            }
            .btn{
                width: 50%;
            }
        }

    }

}

.main-form-md{
    @media (max-width: $screen-lg) {

        .form-container{
            margin-bottom : $line-height-computed;
            text-align    : center;
            .form-inline{
                width      : 100%;
                text-align : center;
            }
        }
        .form-information{
            text-align: center;
        }

    }
}
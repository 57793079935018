.select2-container {
    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    position: relative;
    vertical-align: middle;
}
.select2-container .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 28px;
    user-select: none;
    -webkit-user-select: none;
}
.select2-container .select2-selection--single .select2-selection__rendered {
    display: block;
    padding-left: 8px;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.select2-container .select2-selection--single .select2-selection__clear {
    position: relative;
}
.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
    padding-right: 8px;
    padding-left: 20px;
}
.select2-container .select2-selection--multiple {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 32px;
    user-select: none;
    -webkit-user-select: none;
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
    display: inline-block;
    overflow: hidden;
    padding-left: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.select2-container .select2-search--inline {
    float: left;
}
.select2-container .select2-search--inline .select2-search__field {
    box-sizing: border-box;
    border: none;
    font-size: 100%;
    margin-top: 5px;
    padding: 0;
}
.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
    -webkit-appearance: none;
}
.select2-dropdown {
    background-color: white;
    border: 1px solid #aaa;
    border-radius: 4px;
    box-sizing: border-box;
    display: block;
    position: absolute;
    left: -100000px;
    width: 100%;
    z-index: 1051;
}
.select2-results {
    display: block;
}
.select2-results__options {
    list-style: none;
    margin: 0;
    padding: 0;
}
.select2-results__option {
    padding: 6px;
    user-select: none;
    -webkit-user-select: none;
}
.select2-results__option[aria-selected] {
    cursor: pointer;
}
.select2-container--open .select2-dropdown {
    left: 0;
}
.select2-container--open .select2-dropdown--above {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.select2-container--open .select2-dropdown--below {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.select2-search--dropdown {
    display: block;
    padding: 4px;
}
.select2-search--dropdown .select2-search__field {
    padding: 4px;
    width: 100%;
    box-sizing: border-box;
}
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
    -webkit-appearance: none;
}
.select2-search--dropdown.select2-search--hide {
    display: none;
}
.select2-close-mask {
    border: 0;
    margin: 0;
    padding: 0;
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    opacity: 0;
    z-index: 99;
    background-color: #fff;
    filter: alpha(opacity=0);
}
.select2-hidden-accessible {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
}
.select2-container--default .select2-selection--single {
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px;
}
.select2-container--default .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #999;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #888 transparent transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    height: 0;
    left: 50%;
    margin-left: -4px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0;
}
.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
    float: left;
}
.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
    left: 1px;
    right: auto;
}
.select2-container--default.select2-container--disabled .select2-selection--single {
    background-color: #eee;
    cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
    display: none;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #888 transparent;
    border-width: 0 4px 5px 4px;
}
.select2-container--default .select2-selection--multiple {
    background-color: white;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: text;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0 5px;
    width: 100%;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
    list-style: none;
}
.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
    color: #999;
    margin-top: 5px;
    float: left;
}
.select2-container--default .select2-selection--multiple .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-top: 5px;
    margin-right: 10px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #999;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 2px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
    color: #333;
}
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice,
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder,
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
    float: right;
}
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
    margin-left: 5px;
    margin-right: auto;
}
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
    margin-left: 2px;
    margin-right: auto;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
    border: solid black 1px;
    outline: 0;
}
.select2-container--default.select2-container--disabled .select2-selection--multiple {
    background-color: #eee;
    cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
    display: none;
}
.select2-container--default.select2-container--open.select2-container--above .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid #aaa;
}
.select2-container--default .select2-search--inline .select2-search__field {
    background: transparent;
    border: none;
    outline: 0;
    box-shadow: none;
    -webkit-appearance: textfield;
}
.select2-container--default .select2-results>.select2-results__options {
    max-height: 200px;
    overflow-y: auto;
}
.select2-container--default .select2-results__option[role=group] {
    padding: 0;
}
.select2-container--default .select2-results__option[aria-disabled=true] {
    color: #999;
}
.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #ddd;
}
.select2-container--default .select2-results__option .select2-results__option {
    padding-left: 1em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
    padding-left: 0;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -1em;
    padding-left: 2em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -2em;
    padding-left: 3em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -3em;
    padding-left: 4em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -4em;
    padding-left: 5em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -5em;
    padding-left: 6em;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #5897fb;
    color: white;
}
.select2-container--default .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
}
.select2-container--classic .select2-selection--single {
    background-color: #f7f7f7;
    border: 1px solid #aaa;
    border-radius: 4px;
    outline: 0;
    background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
    background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
    background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}
.select2-container--classic .select2-selection--single:focus {
    border: 1px solid #5897fb;
}
.select2-container--classic .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px;
}
.select2-container--classic .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-right: 10px;
}
.select2-container--classic .select2-selection--single .select2-selection__placeholder {
    color: #999;
}
.select2-container--classic .select2-selection--single .select2-selection__arrow {
    background-color: #ddd;
    border: none;
    border-left: 1px solid #aaa;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px;
    background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
    background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
    background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0);
}
.select2-container--classic .select2-selection--single .select2-selection__arrow b {
    border-color: #888 transparent transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    height: 0;
    left: 50%;
    margin-left: -4px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0;
}
.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
    float: left;
}
.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
    border: none;
    border-right: 1px solid #aaa;
    border-radius: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    left: 1px;
    right: auto;
}
.select2-container--classic.select2-container--open .select2-selection--single {
    border: 1px solid #5897fb;
}
.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
    background: transparent;
    border: none;
}
.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #888 transparent;
    border-width: 0 4px 5px 4px;
}
.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
    background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
    background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}
.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
    background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
    background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0);
}
.select2-container--classic .select2-selection--multiple {
    background-color: white;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: text;
    outline: 0;
}
.select2-container--classic .select2-selection--multiple:focus {
    border: 1px solid #5897fb;
}
.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
    list-style: none;
    margin: 0;
    padding: 0 5px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__clear {
    display: none;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
    color: #888;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 2px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
    color: #555;
}
.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
    float: right;
}
.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
    margin-left: 5px;
    margin-right: auto;
}
.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
    margin-left: 2px;
    margin-right: auto;
}
.select2-container--classic.select2-container--open .select2-selection--multiple {
    border: 1px solid #5897fb;
}
.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.select2-container--classic .select2-search--dropdown .select2-search__field {
    border: 1px solid #aaa;
    outline: 0;
}
.select2-container--classic .select2-search--inline .select2-search__field {
    outline: 0;
    box-shadow: none;
}
.select2-container--classic .select2-dropdown {
    background-color: white;
    border: 1px solid transparent;
}
.select2-container--classic .select2-dropdown--above {
    border-bottom: none;
}
.select2-container--classic .select2-dropdown--below {
    border-top: none;
}
.select2-container--classic .select2-results>.select2-results__options {
    max-height: 200px;
    overflow-y: auto;
}
.select2-container--classic .select2-results__option[role=group] {
    padding: 0;
}
.select2-container--classic .select2-results__option[aria-disabled=true] {
    color: grey;
}
.select2-container--classic .select2-results__option--highlighted[aria-selected] {
    background-color: #3875d7;
    color: white;
}
.select2-container--classic .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
}
.select2-container--classic.select2-container--open .select2-dropdown {
    border-color: #5897fb;
}
/********************************************/

//
// Variables
// --------------------------------------------------
// Variables directly translating Bootstrap variables
// -------------------------
$s2bs-border-radius-base: $border-radius-base !default;
$s2bs-border-radius-large: $border-radius-large !default;
$s2bs-border-radius-small: $border-radius-small !default;
$s2bs-btn-default-bg: $btn-default-bg !default;
$s2bs-btn-default-border: $btn-default-border !default;
$s2bs-btn-default-color: $btn-default-color !default;
$s2bs-caret-width-base: $caret-width-base !default;
$s2bs-caret-width-large: $caret-width-large !default;
$s2bs-cursor-disabled: $cursor-disabled !default;
$s2bs-dropdown-header-color: $dropdown-header-color !default;
$s2bs-dropdown-link-active-bg: $dropdown-link-active-bg !default;
$s2bs-dropdown-link-active-color: $dropdown-link-active-color !default;
$s2bs-dropdown-link-disabled-color: $dropdown-link-disabled-color !default;
$s2bs-dropdown-link-hover-bg: $dropdown-link-hover-bg !default;
$s2bs-dropdown-link-hover-color: $dropdown-link-hover-color !default;
$s2bs-font-size-base: $font-size-base !default;
$s2bs-font-size-large: $font-size-large !default;
$s2bs-font-size-small: $font-size-small !default;
$s2bs-padding-base-horizontal: $padding-base-horizontal !default;
$s2bs-padding-large-horizontal: $padding-large-horizontal !default;
$s2bs-padding-small-horizontal: $padding-small-horizontal !default;
$s2bs-padding-base-vertical: $padding-base-vertical !default;
$s2bs-padding-large-vertical: $padding-large-vertical !default;
$s2bs-padding-small-vertical: $padding-small-vertical !default;
$s2bs-line-height-base: $line-height-base !default;
$s2bs-line-height-large: $line-height-large !default;
$s2bs-line-height-small: $line-height-small !default;
$s2bs-input-bg: $input-bg !default;
$s2bs-input-bg-disabled: $input-bg-disabled !default;
$s2bs-input-color: $input-color !default;
$s2bs-input-color-placeholder: $input-color-placeholder !default;
$s2bs-input-border: $input-border !default;
$s2bs-input-border-focus: $input-border-focus !default;
$s2bs-input-border-radius: $input-border-radius !default;
$s2bs-input-height-base: $input-height-base !default;
$s2bs-input-height-large: $input-height-large !default;
$s2bs-input-height-small: $input-height-small !default;
// Theme-specific variables
// -------------------------
$s2bs-dropdown-arrow-color: $s2bs-input-color-placeholder !default;
$s2bs-dropdown-box-shadow: 0 6px 12px rgba(0, 0, 0, .175) !default;
$s2bs-dropdown-box-shadow-above: 0px -6px 12px rgba(0, 0, 0, .175) !default;
$s2bs-clear-selection-color: $s2bs-dropdown-arrow-color !default;
$s2bs-clear-selection-hover-color: $s2bs-btn-default-color !default;
$s2bs-remove-choice-color: $s2bs-input-color-placeholder !default;
$s2bs-remove-choice-hover-color: $s2bs-btn-default-color !default;
$s2bs-selection-choice-border-radius: $s2bs-border-radius-base !default;
$s2bs-dropdown-header-padding-vertical: $s2bs-padding-base-vertical !default;
$s2bs-dropdown-header-font-size: $s2bs-font-size-small !default;
// Bootstrap defaults not available as pre-defined variables
// These should not be overridden.
// -------------------------
// @see https://github.com/twbs/bootstrap-sass/blob/master/assets/stylesheets/bootstrap/_forms.scss#L126
$s2bs-form-control-default-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075) !default;
// @see https://github.com/twbs/bootstrap-sass/blob/master/assets/stylesheets/bootstrap/mixins/_forms.scss#L60
$s2bs-form-control-focus-box-shadow: $s2bs-form-control-default-box-shadow,
0 0 8px rgba($s2bs-input-border-focus, 0.6) !default;
// @see https://github.com/twbs/bootstrap-sass/blob/master/assets/stylesheets/bootstrap/_forms.scss#L127
$s2bs-form-control-transition: border-color ease-in-out .15s,
box-shadow ease-in-out .15s !default;
//
// Mixins
// --------------------------------------------------
// @see https://github.com/twbs/bootstrap-sass/blob/master/assets/stylesheets/bootstrap/_forms.scss#L115
@mixin bootstrap-input-defaults {
    //@include box-shadow($s2bs-form-control-default-box-shadow);
    background-color: $s2bs-input-bg;
    border: 1px solid $s2bs-input-border;
    border-radius: $s2bs-input-border-radius;
    color: $s2bs-input-color;
    font-size: $s2bs-font-size-base;
}
// @see http://getbootstrap.com/css/#forms-control-validation
// @see https://github.com/twbs/bootstrap-sass/blob/master/assets/stylesheets/bootstrap/_forms.scss#L388
@mixin validation-state-focus($color) {
    $shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px lighten($color, 20%);
    .select2-dropdown,
    .select2-selection {
        border-color: $color;
    }
    .select2-container--focus .select2-selection,
    .select2-container--open .select2-selection {
        //@include box-shadow($shadow);
        border-color: darken($color, 10%);
    }
    &.select2-drop-active {
        border-color: darken($color, 10%);
        &.select2-drop.select2-drop-above {
            border-top-color: darken($color, 10%);
        }
    }
}
// dropdown arrow when dropdown is open
@mixin dropdown-arrow {
    .select2-selection--single {
        /**
* Make the dropdown arrow point up while the dropdown is visible.
*/
        .select2-selection__arrow b {
            border-color: transparent transparent $s2bs-dropdown-arrow-color transparent;
            border-width: 0 $s2bs-caret-width-large $s2bs-caret-width-large $s2bs-caret-width-large;
        }
    }
}
.select2-container--bootstrap {
    display: block;
    /*------------------------------------*\
#COMMON STYLES
\*------------------------------------*/
    .select2-selection {
        @include bootstrap-input-defaults;
        outline: 0;
        &.form-control {
            border-radius: $s2bs-border-radius-base;
        }
    }
    /**
* Search field in the Select2 dropdown.
*/
    .select2-search--dropdown {
        .select2-search__field {
            @include bootstrap-input-defaults;
        }
    }
    /**
* No outline for all search fields - in the dropdown
* and inline in multi Select2s.
*/
    .select2-search__field {
        outline: 0;
        &::-webkit-input-placeholder {
            color: $s2bs-input-color-placeholder;
        }
        /* Firefox 18- */
        &:-moz-placeholder {
            color: $s2bs-input-color-placeholder;
        }
        /**
* Firefox 19+
*
* @see http://stackoverflow.com/questions/24236240/color-for-styled-placeholder-text-is-muted-in-firefox
*/
        &::-moz-placeholder {
            color: $s2bs-input-color-placeholder;
            opacity: 1;
        }
        &:-ms-input-placeholder {
            color: $s2bs-input-color-placeholder;
        }
    }
    /**
* Adjust Select2's choices hover and selected styles to match
* Bootstrap 3's default dropdown styles.
*
* @see http://getbootstrap.com/components/#dropdowns
*/
    .select2-results__option {
        padding: $s2bs-padding-base-vertical $s2bs-padding-base-horizontal;
        &[role=group] {
            padding: 0;
        }
        /**
* Disabled results.
*
* @see https://select2.github.io/examples.html#disabled-results
*/
        &[aria-disabled=true] {
            color: $s2bs-dropdown-link-disabled-color;
            cursor: $s2bs-cursor-disabled;
        }
        /**
* Hover state.
*/
        &[aria-selected=true] {
            background-color: $s2bs-dropdown-link-hover-bg;
            color: $s2bs-dropdown-link-hover-color;
        }
        /**
* Selected state.
*/
        &--highlighted[aria-selected] {
            background-color: $s2bs-dropdown-link-active-bg;
            color: $s2bs-dropdown-link-active-color;
        }
        .select2-results__option {
            padding: $s2bs-padding-base-vertical $s2bs-padding-base-horizontal;
            .select2-results__group {
                padding-left: 0;
            }
            .select2-results__option {
                margin-left: -$s2bs-padding-base-horizontal;
                padding-left: $s2bs-padding-base-horizontal*2;
                .select2-results__option {
                    margin-left: -$s2bs-padding-base-horizontal*2;
                    padding-left: $s2bs-padding-base-horizontal*3;
                    .select2-results__option {
                        margin-left: -$s2bs-padding-base-horizontal*3;
                        padding-left: $s2bs-padding-base-horizontal*4;
                        .select2-results__option {
                            margin-left: -$s2bs-padding-base-horizontal*4;
                            padding-left: $s2bs-padding-base-horizontal*5;
                            .select2-results__option {
                                margin-left: -$s2bs-padding-base-horizontal*5;
                                padding-left: $s2bs-padding-base-horizontal*6;
                            }
                        }
                    }
                }
            }
        }
    }
    .select2-results__group {
        color: $s2bs-dropdown-header-color;
        display: block;
        padding: $s2bs-dropdown-header-padding-vertical $s2bs-padding-base-horizontal;
        font-size: $s2bs-dropdown-header-font-size;
        line-height: $s2bs-line-height-base;
        white-space: nowrap;
    }
    &.select2-container--focus,
    &.select2-container--open {
        .select2-selection {
            //@include box-shadow($s2bs-form-control-focus-box-shadow);
            @include transition($s2bs-form-control-transition);
            border-color: $s2bs-input-border-focus;
        }
    }
    &.select2-container--open {
        /**
* Make the dropdown arrow point up while the dropdown is visible.
*/
        .select2-selection .select2-selection__arrow b {
            border-color: transparent transparent $s2bs-dropdown-arrow-color transparent;
            border-width: 0 $s2bs-caret-width-base $s2bs-caret-width-base $s2bs-caret-width-base;
        }
        /**
* Handle border radii of the container when the dropdown is showing.
*/
        &.select2-container--below {
            .select2-selection {
                @include border-bottom-radius(0);
                border-bottom-color: transparent;
            }
        }
        &.select2-container--above {
            .select2-selection {
                @include border-top-radius(0);
                border-top-color: transparent;
            }
        }
    }
    /**
* Clear the selection.
*/
    .select2-selection__clear {
        color: $s2bs-clear-selection-color;
        cursor: pointer;
        float: right;
        font-weight: bold;
        margin-right: 10px;
        &:hover {
            color: $s2bs-clear-selection-hover-color;
        }
    }
    /**
* Address disabled Select2 styles.
*
* @see https://select2.github.io/examples.html#disabled
* @see http://getbootstrap.com/css/#forms-control-disabled
*/
    &.select2-container--disabled {
        .select2-selection {
            border-color: $s2bs-input-border;
            @include box-shadow(none);
        }
        .select2-selection,
        .select2-search__field {
            cursor: $s2bs-cursor-disabled;
        }
        .select2-selection,
        .select2-selection--multiple .select2-selection__choice {
            background-color: $s2bs-input-bg-disabled;
        }
        .select2-selection__clear,
        .select2-selection--multiple .select2-selection__choice__remove {
            display: none;
        }
    }
    /*------------------------------------*\
#DROPDOWN
\*------------------------------------*/
    /**
* Dropdown border color and box-shadow.
*/
    .select2-dropdown {
        //@include box-shadow($s2bs-dropdown-box-shadow);
        border-color: $s2bs-input-border-focus;
        overflow-x: hidden;
        margin-top: -1px;
        &--above {
            //@include box-shadow($s2bs-dropdown-box-shadow-above);
            margin-top: 1px;
        }
    }
    /**
* Limit the dropdown height.
*/
    .select2-results>.select2-results__options {
        max-height: 200px;
        overflow-y: auto;
    }
    /*------------------------------------*\
#SINGLE SELECT2
\*------------------------------------*/
    .select2-selection--single {
        height: $s2bs-input-height-base;
        line-height: $s2bs-line-height-base;
        padding: $s2bs-padding-base-vertical ($s2bs-padding-base-horizontal + $s2bs-caret-width-base*3) $s2bs-padding-base-vertical $s2bs-padding-base-horizontal;
        /**
* Adjust the single Select2's dropdown arrow button appearance.
*/
        .select2-selection__arrow {
            position: absolute;
            bottom: 0;
            right: $s2bs-padding-base-horizontal;
            top: 0;
            width: $s2bs-caret-width-base;
            b {
                border-color: $s2bs-dropdown-arrow-color transparent transparent transparent;
                border-style: solid;
                border-width: $s2bs-caret-width-base $s2bs-caret-width-base 0 $s2bs-caret-width-base;
                height: 0;
                left: 0;
                margin-left: -$s2bs-caret-width-base;
                margin-top: -$s2bs-caret-width-base/2;
                position: absolute;
                top: 50%;
                width: 0;
            }
        }
        .select2-selection__rendered {
            color: $s2bs-input-color;
            padding: 0;
        }
        .select2-selection__placeholder {
            color: $s2bs-input-color-placeholder;
        }
    }
    /*------------------------------------*\
#MULTIPLE SELECT2
\*------------------------------------*/
    .select2-selection--multiple {
        min-height: $s2bs-input-height-base;
        padding: 0;
        height: auto;
        .select2-selection__rendered {
            box-sizing: border-box;
            display: block;
            line-height: $s2bs-line-height-base;
            list-style: none;
            margin: 0;
            overflow: hidden;
            padding: 0;
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .select2-selection__placeholder {
            color: $s2bs-input-color-placeholder;
            float: left;
            margin-top: 5px;
        }
        /**
* Make Multi Select2's choices match Bootstrap 3's default button styles.
*/
        .select2-selection__choice {
            color: $s2bs-input-color;
            background: $s2bs-btn-default-bg;
            border: 1px solid $s2bs-btn-default-border;
            border-radius: $s2bs-selection-choice-border-radius;
            cursor: default;
            float: left;
            margin: ($s2bs-padding-base-vertical - 1) 0 0 $s2bs-padding-base-horizontal/2;
            padding: 0 $s2bs-padding-base-vertical;
        }
        /**
* Minus 2px borders.
*/
        .select2-search--inline {
            .select2-search__field {
                background: transparent;
                padding: 0 $s2bs-padding-base-horizontal;
                height: $s2bs-input-height-base - 2;
                line-height: $s2bs-line-height-base;
                margin-top: 0;
                min-width: 5em;
            }
        }
        .select2-selection__choice__remove {
            color: $s2bs-remove-choice-color;
            cursor: pointer;
            display: inline-block;
            font-weight: bold;
            margin-right: $s2bs-padding-base-vertical / 2;
            &:hover {
                color: $s2bs-remove-choice-hover-color;
            }
        }
        /**
* Clear the selection.
*/
        .select2-selection__clear {
            margin-top: $s2bs-padding-base-vertical;
        }
    }
    /**
* Address Bootstrap control sizing classes
*
* 1. Reset Bootstrap defaults.
* 2. Adjust the dropdown arrow button icon position.
*
* @see http://getbootstrap.com/css/#forms-control-sizes
*/
    /* 1 */
    .select2-selection--single.input-sm,
    .input-group-sm & .select2-selection--single,
    .form-group-sm & .select2-selection--single {
        border-radius: $s2bs-border-radius-small;
        font-size: $s2bs-font-size-small;
        height: $s2bs-input-height-small;
        line-height: $s2bs-line-height-small;
        padding: $s2bs-padding-small-vertical $s2bs-padding-small-horizontal + $s2bs-caret-width-base*3 $s2bs-padding-small-vertical $s2bs-padding-small-horizontal;
        /* 2 */
        .select2-selection__arrow b {
            margin-left: -$s2bs-padding-small-vertical;
        }
    }
    .select2-selection--multiple.input-sm,
    .input-group-sm & .select2-selection--multiple,
    .form-group-sm & .select2-selection--multiple {
        min-height: $s2bs-input-height-small;
        border-radius: $s2bs-border-radius-small;
        .select2-selection__choice {
            font-size: $s2bs-font-size-small;
            line-height: $s2bs-line-height-small;
            margin: ($s2bs-padding-small-vertical - 1) 0 0 $s2bs-padding-small-horizontal/2;
            padding: 0 $s2bs-padding-small-vertical;
        }
        .select2-search--inline .select2-search__field {
            padding: 0 $s2bs-padding-small-horizontal;
            font-size: $s2bs-font-size-small;
            height: $s2bs-input-height-small - 2;
            line-height: $s2bs-line-height-small;
        }
        .select2-selection__clear {
            margin-top: $s2bs-padding-small-vertical;
        }
    }
    .select2-selection--single.input-lg,
    .input-group-lg & .select2-selection--single,
    .form-group-lg & .select2-selection--single {
        border-radius: $s2bs-border-radius-large;
        font-size: $s2bs-font-size-large;
        height: $s2bs-input-height-large;
        line-height: $s2bs-line-height-large;
        padding: $s2bs-padding-large-vertical $s2bs-padding-large-horizontal + $s2bs-caret-width-large*3 $s2bs-padding-large-vertical $s2bs-padding-large-horizontal;
        /* 1 */
        .select2-selection__arrow {
            width: $s2bs-caret-width-large;
            b {
                border-width: $s2bs-caret-width-large $s2bs-caret-width-large 0 $s2bs-caret-width-large;
                margin-left: -$s2bs-caret-width-large;
                margin-left: -$s2bs-padding-large-vertical;
                margin-top: -$s2bs-caret-width-large/2;
            }
        }
    }
    .select2-selection--multiple.input-lg,
    .input-group-lg & .select2-selection--multiple,
    .form-group-lg & .select2-selection--multiple {
        min-height: $s2bs-input-height-large;
        border-radius: $s2bs-border-radius-large;
        .select2-selection__choice {
            font-size: $s2bs-font-size-large;
            line-height: $s2bs-line-height-large;
            border-radius: $s2bs-selection-choice-border-radius;
            margin: ($s2bs-padding-large-vertical - 1) 0 0 $s2bs-padding-large-horizontal/2;
            padding: 0 $s2bs-padding-large-vertical;
        }
        .select2-search--inline .select2-search__field {
            padding: 0 $s2bs-padding-large-horizontal;
            font-size: $s2bs-font-size-large;
            height: $s2bs-input-height-large - 2;
            line-height: $s2bs-line-height-large;
        }
        .select2-selection__clear {
            margin-top: $s2bs-padding-large-vertical;
        }
    }
    .select2-selection.input-lg.select2-container--open {
        @include dropdown-arrow;
    }
    .input-group-lg & .select2-selection {
        &.select2-container--open {
            @include dropdown-arrow;
        }
    }
    /*------------------------------------*\
#RTL SUPPORT
\*------------------------------------*/
    &[dir="rtl"] {
        /**
* Single Select2
*
* 1. Makes sure that .select2-selection__placeholder is positioned
*    correctly.
*/
        .select2-selection--single {
            padding-left: $s2bs-padding-base-horizontal + $s2bs-caret-width-base*3;
            padding-right: $s2bs-padding-base-horizontal;
            .select2-selection__rendered {
                padding-right: 0;
                padding-left: 0;
                text-align: right;
                /* 1 */
            }
            .select2-selection__clear {
                float: left;
            }
            .select2-selection__arrow {
                left: $s2bs-padding-base-horizontal;
                right: auto;
                b {
                    margin-left: 0;
                }
            }
        }
        /**
* Multiple Select2
*/
        .select2-selection--multiple {
            .select2-selection__choice,
            .select2-selection__placeholder,
            .select2-search--inline {
                float: right;
            }
            .select2-selection__choice {
                margin-left: 0;
                margin-right: $s2bs-padding-base-horizontal/2;
            }
            .select2-selection__choice__remove {
                margin-left: 2px;
                margin-right: auto;
            }
        }
    }
}
/*------------------------------------*\
#ADDITIONAL GOODIES
\*------------------------------------*/

/**
* Address Bootstrap's validation states
*
* If a Select2 widget parent has one of Bootstrap's validation state modifier
* classes, adjust Select2's border colors and focus states accordingly.
* You may apply said classes to the Select2 dropdown (body > .select2-container)
* via JavaScript match Bootstraps' to make its styles match.
*
* @see http://getbootstrap.com/css/#forms-control-validation
*/

.has-warning {
    @include validation-state-focus($state-warning-text);
}
.has-error {
    @include validation-state-focus($state-danger-text);
}
.has-success {
    @include validation-state-focus($state-success-text);
}
/**
* Select2 widgets in Bootstrap Input Groups
*
* @see http://getbootstrap.com/components/#input-groups
* @see https://github.com/twbs/bootstrap/blob/master/less/input-groups.less
*/

/**
* Reset rounded corners
*/

.input-group>.select2-hidden-accessible {
    &:first-child+.select2-container--bootstrap>.selection>.select2-selection,
    &:first-child+.select2-container--bootstrap>.selection>.select2-selection.form-control {
        @include border-right-radius(0);
    }
    &:not(:first-child)+.select2-container--bootstrap:not(:last-child)>.selection>.select2-selection,
    &:not(:first-child)+.select2-container--bootstrap:not(:last-child)>.selection>.select2-selection.form-control {
        border-radius: 0;
    }
    &:not(:first-child):not(:last-child)+.select2-container--bootstrap:last-child>.selection>.select2-selection,
    &:not(:first-child):not(:last-child)+.select2-container--bootstrap:last-child>.selection>.select2-selection.form-control {
        @include border-left-radius(0);
    }
}
.input-group>.select2-container--bootstrap {
    display: table;
    table-layout: fixed;
    position: relative;
    z-index: 2;
    width: 100%;
    margin-bottom: 0;
    >.selection>.select2-selection.form-control {
        float: none;
    }
    /**
* Adjust z-index like Bootstrap does to show the focus-box-shadow
* above appended buttons in .input-group and .form-group.
*/
    &.select2-container--open,
    /* .form-group */
    &.select2-container--focus/* .input-group */
        {
        z-index: 3;
    }
    /**
* Adjust alignment of Bootstrap buttons in Bootstrap Input Groups to address
* Multi Select2's height which - depending on how many elements have been selected -
* may grow taller than its initial size.
*
* @see http://getbootstrap.com/components/#input-groups
*/
    &,
    .input-group-btn,
    .input-group-btn .btn {
        vertical-align: top;
    }
}
/**
* Temporary fix for https://github.com/select2/select2-bootstrap-theme/issues/9
*
* Provides `!important` for certain properties of the class applied to the
* original `<select>` element to hide it.
*
* @see https://github.com/select2/select2/pull/3301
* @see https://github.com/fk/select2/commit/31830c7b32cb3d8e1b12d5b434dee40a6e753ada
*/

.form-control.select2-hidden-accessible {
    position: absolute !important;
    width: 1px !important;
}
/**
* Display override for inline forms
*/

.form-inline .select2-container--bootstrap {
    @media (min-width: $screen-sm-min) {
        display: inline-block;
    }
}

.question-component{
	.text-danger{
		color: rgb(255,50,50) !important;
	}
}


/**
 * Alternativas horizontales [_Definition]
 */
.alternative-horizontal{
	.alternative-group{
		margin-bottom: 15px;
		&:last-child{
			margin-bottom: 0;
		}
		.input-group-addon{
			background: transparent;
			input[type="radio"],
			input[type="checkbox"]{
				top    : 4px;
				margin : 0;
			}
		}
	}
}

/**
 * Botón de agregar alternativa [_Definition]
 */

.btn-add-alternative{
	margin-top : 15px;
	text-align : left;
}

/**
 * Listado de preguntas [Lado de izquierdo] [_Definition]
 */
.question-list{
	.list-group{
		margin-bottom: 0;
		.list-group-item{
			margin-bottom : 15px;
			border-left   : 4px solid #ddd;
	        &:last-child{
	        	margin-bottom: 0;
	        }
		}
		.item-active{
			border-left: 4px solid rgb(135,150,215);
		}
	}
	.alert{
		margin-bottom: 0px;
	}
}

/**
 * Input group code [_Definition]
 */
.input-group-code{
	textarea{
		resize: vertical;
	}
	.input-group-btn{
		vertical-align: top;
		.btn{
			outline : none;
			height  : calc(100% + 16px);
		}
	}
}

/**
 * Radio [_Definition]
 */
.radio-group{
	border     : 1px solid $brand-border;
	box-shadow : inset 0 1px 1px rgba(0,0,0,0.075);
	.radio, .checkbox{
		display     : table-cell;
		float       : none;
		width       : 1%;
		border-left : 1px solid $brand-border;
		text-align  : left;
		padding     : 0;
		label{
			width: 100%;
		    text-align: center;
		    padding: 9px 15px;
		}
	    &:first-child{
	    	border-left: 0;
	    }
	}

	@media (max-width: $screen-sm) {

		display: block;

		.radio, .checkbox{
			display     : block;
			width       : 100%;
			text-align  : left;
			border-top  : 1px solid $brand-border;
			border-left : 0;
			margin      : 0;
			padding     : 2px 16px;
		    &:first-child{
		    	border-top: 0;
		    }
		    label{
				width          : 100%;
				padding-top    : 10px;
				padding-bottom : 10px;
				text-align: left;
		    }
		}
	}
}

.question-container-main{
	span.text-danger{
		font-weight: 700;
	}
}
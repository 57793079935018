//
// Modals
// --------------------------------------------------

// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and shit

// Kill the scroll on the body
.modal-open {
  overflow: hidden;
}

// Container that the modal scrolls within
.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal;
  -webkit-overflow-scrolling: touch;

  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;

  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;

  // When fading in the modal, animate it to slide down
  &.fade .modal-dialog {
    position : relative;
    top      : 50%;
    margin   : 0 auto;
    @include translate(0, -75%);
    @include transition-transform(0.3s ease-out);
  }
  &.in .modal-dialog { @include translate(0, -50%) }
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

// Shell div to position the modal with bottom padding
.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

// Actual modal
.modal-content {
  position: relative;
  background-color: $modal-content-bg;
  //border: 1px solid $modal-content-fallback-border-color; //old browsers fallback (ie8 etc)
  //border: 1px solid $modal-content-border-color;
  border-radius: $border-radius-large;
  @include box-shadow(0 3px 9px rgba(0,0,0,.5));
  background-clip: padding-box;
  // Remove focus outline from opened modal
  outline: 0;
}

// Modal background
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal-background;
  background-color: $modal-backdrop-bg;
  // Fade for backdrop
  &.fade { @include opacity(0); }
  &.in { @include opacity($modal-backdrop-opacity); }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  padding: $modal-title-padding;
  //border-bottom: 1px solid $modal-header-border-color;
  @include clearfix;
  h3{
    text-align: center;
    font-size: 15px;
    font-weight: 500;
  }
  .subtext-modal{
    margin: 0;
  }
  .center-image{
    width: 72px;
    height: 72px;
  }
}
// Close icon
.modal-header .close {
  margin-top: -2px;
}

// Title text within header
.modal-title {
  font-size: 15px;
  line-height: 24px;
  margin: 0;
  text-align: center;
  color: rgb(30,30,30);
  //text-transform: uppercase;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  position: relative;
  padding: 0 $modal-inner-padding $modal-inner-padding;
}

.modal-form{
  .control-label {
    //padding-top: 0;
    text-align:left !important;
  }
  .form-group{
    &:last-child{
      margin-bottom: 0;
    }
  }
}

$btn-size    : 52px;
$border-size : 3px;
.list-share{
  padding: 0;
  margin: 0;
  text-align: center;
  li{
    display: inline-block;
    margin: 0 10px;
    a{
      width: $btn-size;
      height: $btn-size;
      line-height: $btn-size;
      padding: 0;
      border-radius: 5px;
      @include box-shadow($material-shadow-2dp);
      &:hover{
        -webkit-animation-name: tada;
        animation-name: tada;
        @include box-shadow($material-shadow-8dp);
      }
      i{
        font-size: 20px;
      }
    }
    .facebook {
      background: $brand-facebook;
      color: white;
    }
    .whatsapp{
      background: $brand-whatsapp;
      color: white;
    }
    .twitter {
      background: $brand-twitter;
      color: white;
    }
    .google {
      background: $brand-google;
      color: white;
    }
    .linkedin{
      background: $brand-linkedin;
      color: white;
    }
    .mail{
      background: $brand-mail;
      color: white;
    }
  }
}

/*
$border-size: 3px;
.list-share{
  padding: 0;
  margin: 0 0 30px;
  text-align: center;
  li{
    display: inline-block;
    margin: 0 10px;
    a{
      width: $btn-size;
      height: $btn-size;
      line-height: $btn-size;
      padding: 0;
      box-shadow: none;
      border-radius: 50%;
      i{
        font-size: 18px;
      }
    }
    .facebook {
      color: $brand-facebook;
      border: $border-size solid $brand-facebook;
      &:hover{
        background: $brand-facebook;
        color: white;
      }
    }
    .twitter {
      color: $brand-twitter;
      border: $border-size solid $brand-twitter;
      &:hover{
        background: $brand-twitter;
        color: white;
      }
    }
    .google {
      color: $brand-google;
      border: $border-size solid $brand-google;
      &:hover{
        background: $brand-google;
        color: white;
      }
    }
    .linkedin{
      color: $brand-linkedin;
      border: $border-size solid $brand-linkedin;
      &:hover{
        background: $brand-linkedin;
        color: white;
      }
    }
    .mail{
      color: $brand-vine;
      border: $border-size solid $brand-vine;
      &:hover{
        background: $brand-vine;
        color: white;
      }
    }
  }
}

 */
// Footer (for actions)
.modal-footer-btn{
    padding: 0 $modal-inner-padding $modal-inner-padding $modal-inner-padding !important;
}
.modal-footer {
  padding: $modal-inner-padding;
  text-align: right; // right align buttons
  border-top: 1px solid $modal-footer-border-color;
  @include clearfix; // clear it in case folks use .pull-* classes on buttons

  // Properly space out buttons
  .btn + .btn {
    margin-left: 5px;
    margin-bottom: 0; // account for input[type="submit"] which gets the bottom margin like all other inputs
  }
  // but override that for button groups
  .btn-group .btn + .btn {
    margin-left: -1px;
  }
  // and override it for block buttons as well
  .btn-block + .btn-block {
    margin-left: 0;
  }
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

// Scale up the modal
@media (min-width: $screen-sm-min) {
  // Automatically set modal's width for larger viewports
  .modal-dialog {
    width: $modal-md;
    margin: 30px auto;
  }
  .modal-content {
    @include box-shadow(0 5px 15px rgba(0,0,0,.5));
  }

  // Modal sizes
  .modal-sm { width: $modal-sm; }
}

@media (min-width: $screen-md-min) {
  .modal-lg { width: $modal-lg; }
}

@media (max-width: $screen-sm-min) {
  .modal-dialog {
    width: auto;
    max-width: $modal-md;
    margin: 30px auto;
  }
}
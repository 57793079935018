.collection-followers{
	.media{
		border: 1px solid #ddd;
	    margin-bottom: 20px;
	    padding: 4px;
	    border-radius: 3px;
		.media-img{
			width: 64px;
			height: 64px;
			display: inline-block;
			img{
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
		}
		.media-body{
		    vertical-align: middle;
		    .heading-container{
		    	width: 95%;
		    }
			h4{
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			    font-size: 15px;
			    margin: 0 0 3px;
			}
			p{
				margin: 0;
			}
		}
	}
	.pagination{
	    margin: 5px 0 0;
	}
}
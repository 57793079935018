//
// Panels
// --------------------------------------------------
// Base class
.panel {
    margin-bottom: $line-height-computed;
    background-color: $panel-bg;
    border: 1px solid transparent;
    border-radius: $panel-border-radius;
    @include box-shadow(0 1px 1px rgba(0, 0, 0, 0.05));
}
/**
 * Panel-lg [_Definition]
 */
.panel-lg {
    .panel-heading {
        padding: 20px;
        .panel-title{
            line-height: 35px;
        }
        .heading-btn {
            position: absolute;
            top: 20px;
            right: 20px;
            box-shadow: none;

            i.fa{
                //display: none;
            }

            @media (max-width: 380px) {
                .btn-text{
                    display: none;
                }
                i.fa{
                    line-height: 24px;
                    display: block;
                }
            }
        }
    }
    .panel-body {}
}
/**
 * Panel-lg [_Definition]
 */
.panel-md {
    .panel-heading {
        .panel-title{
            //font-weight: 400;
            line-height: $line-height-base;
        }
    }
    .panel-body {
        .form-group{
            margin-bottom: 20px;
        }
    }
}
/**
 * Panel-xs [_Definition]
 */
.panel-xs {
    .panel-heading {
        padding: 15px;
        .panel-title{
        	font-weight: 400;
            line-height: $line-height-base;
        }
    }
    .panel-body {
    	padding: 15px;
    }
    .panel-xs{
        margin-bottom: 15px;
    }
}
/**
 * Panel Elevation [_Definition]
 */
.panel-elevation {
    border: 0;
    margin: 0;
    @include box-shadow($material-shadow-1dp);
}

.panel-whiteframe {
    border: 0;
    @include box-shadow($material-shadow-1dp);
}
/**
 * Panel-form [_Definition]
 */
.panel-form {
    padding: $panel-body-padding;
    border-bottom: 1px solid $brand-border;
    .form-container {
        width: 100%;
        .form-inline {}
        .input-group {}
    }
    .form-pagination {
        width: 100%;
        text-align: right;
        > div {
            display: inline;
        }
        .text-pagination {
            width: 100%;
            margin-right: 10px;
        }
    }
    @media (max-width: $screen-md) {
        .form-container {
            margin-bottom: $line-height-computed;
            text-align: center;
            .form-inline {
                width: 100%;
                text-align: center;
            }
        }
        .form-pagination {
            > div {
                display: inline-block;
            }
            text-align: center;
            .text-pagination {
                margin-bottom: 15px;
            }
        }
    }
    @media (max-width: $screen-sm) {
        .form-container {
            .input-group {
                width: 100%;
                margin-bottom: 15px;
            }
            .btn {
                width: 50%;
            }
        }
    }
}

// Panel contents
.panel-body {
    padding: $panel-body-padding;
    @include clearfix;
}

// Optional heading
.panel-heading {
    padding: $panel-heading-padding;
    border-bottom: 1px solid transparent;
    @include border-top-radius(($panel-border-radius - 1));
    position: relative;
    > .dropdown .dropdown-toggle {
        color: inherit;
    }
    .panel-counter {
        position: absolute;
        right: $panel-heading-padding;
        top: $panel-heading-padding - 3px;
    }
    .panel-total{
        position: absolute;
        right: $panel-heading-padding;
        top: $panel-heading-padding;
    }
    .heading-btn-xs {
        position: absolute;
        top: 14px;
        right: 20px;
        box-shadow: none;
    }
}

.panel-subheading{
    padding: 20px;
    color: #333333;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    text-align: left;
}

.heading-fluid{
    @media (max-width: $screen-xs) {
        width: 100%;
        text-align: center;
        .panel-total{
            position: relative;
            right: auto;
            top: auto;
            text-align: center;
            margin-top: 10px;
        }
    }
}

.panel-heading-search{
    .panel-title{
        line-height: 35px;
    }
    .form-container{
        position : absolute;
        top      : 28px;
        right    : 20px;
        width    : 300px;
        @media (max-width: $screen-xs) {

            position: relative;
            top: auto;
            right: auto;
            width: 100%;
            margin-top: 10px;
        }
        .form-search{

        }
    }
}

// Within heading, strip any `h*` tag of its default margins for spacing.
.panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: ceil(($font-size-base * 1.125));
    color: inherit;
    line-height: $line-height-computed;
    > a,
    > small,
    > .small,
    > small > a,
    > .small > a {
        color: inherit;
    }
}

// Optional footer (stays gray in every modifier class)
.panel-footer {
    padding: $panel-footer-padding;
    background-color: $panel-footer-bg;
    border-top: 1px solid $panel-inner-border;
    @include border-bottom-radius(($panel-border-radius - 1));
}

// List groups in panels
//
// By default, space out list group content from panel headings to account for
// any kind of custom content between the two.
.panel {
    > .list-group,
    > .panel-collapse > .list-group {
        margin-bottom: 0;
        .list-group-item {
            border-width: 1px 0;
            border-radius: 0;
        }
        // Add border top radius for first one
        &:first-child {
            .list-group-item:first-child {
                border-top: 0;
                @include border-top-radius(($panel-border-radius - 1));
            }
        }
        // Add border bottom radius for last one
        &:last-child {
            .list-group-item:last-child {
                border-bottom: 0;
                @include border-bottom-radius(($panel-border-radius - 1));
            }
        }
    }
    > .panel-heading + .panel-collapse > .list-group {
        .list-group-item:first-child {
            @include border-top-radius(0);
        }
    }
}

// Collapse space between when there's no additional content.
.panel-heading + .list-group {
    .list-group-item:first-child {
        border-top-width: 0;
    }
}

.list-group + .panel-footer {
    border-top-width: 0;
}

// Tables in panels
//
// Place a non-bordered `.table` within a panel (not within a `.panel-body`) and
// watch it go full width.
.panel {
    > .table,
    > .table-responsive > .table,
    > .panel-collapse > .table {
        margin-bottom: 0;
        caption {
            padding-left: $panel-body-padding;
            padding-right: $panel-body-padding;
        }
    }
    // Add border top radius for first one
    > .table:first-child,
    > .table-responsive:first-child > .table:first-child {
        @include border-top-radius(($panel-border-radius - 1));
        > thead:first-child,
        > tbody:first-child {
            > tr:first-child {
                border-top-left-radius: ($panel-border-radius - 1);
                border-top-right-radius: ($panel-border-radius - 1);
                td:first-child,
                th:first-child {
                    border-top-left-radius: ($panel-border-radius - 1);
                }
                td:last-child,
                th:last-child {
                    border-top-right-radius: ($panel-border-radius - 1);
                }
            }
        }
    }
    // Add border bottom radius for last one
    > .table:last-child,
    > .table-responsive:last-child > .table:last-child {
        @include border-bottom-radius(($panel-border-radius - 1));
        > tbody:last-child,
        > tfoot:last-child {
            > tr:last-child {
                border-bottom-left-radius: ($panel-border-radius - 1);
                border-bottom-right-radius: ($panel-border-radius - 1);
                td:first-child,
                th:first-child {
                    border-bottom-left-radius: ($panel-border-radius - 1);
                }
                td:last-child,
                th:last-child {
                    border-bottom-right-radius: ($panel-border-radius - 1);
                }
            }
        }
    }
    > .panel-body + .table,
    > .panel-body + .table-responsive,
    > .table + .panel-body,
    > .table-responsive + .panel-body {
        border-top: 1px solid $table-border-color;
    }
    > .table > tbody:first-child > tr:first-child th,
    > .table > tbody:first-child > tr:first-child td {
        border-top: 0;
    }
    > .table-bordered,
    > .table-responsive > .table-bordered {
        border: 0;
        > thead,
        > tbody,
        > tfoot {
            > tr {
                > th:first-child,
                > td:first-child {
                    border-left: 0;
                }
                > th:last-child,
                > td:last-child {
                    border-right: 0;
                }
            }
        }
        > thead,
        > tbody {
            > tr:first-child {
                > td,
                > th {
                    border-bottom: 0;
                }
            }
        }
        > tbody,
        > tfoot {
            > tr:last-child {
                > td,
                > th {
                    border-bottom: 0;
                }
            }
        }
    }
    > .table-responsive {
        border: 0;
        margin-bottom: 0;
    }
}

// Collapsable panels (aka, accordion)
//
// Wrap a series of panels in `.panel-group` to turn them into an accordion with
// the help of our collapse JavaScript plugin.
.panel-group {
    margin-bottom: $line-height-computed;
    // Tighten up margin so it's only between panels
    .panel {
        margin-bottom: 0;
        border-radius: $panel-border-radius;
        + .panel {
            margin-top: 5px;
        }
    }
    .panel-heading {
        border-bottom: 0;
        + .panel-collapse > .panel-body,
        + .panel-collapse > .list-group {
            border-top: 1px solid $panel-inner-border;
        }
    }
    .panel-footer {
        border-top: 0;
        + .panel-collapse .panel-body {
            border-bottom: 1px solid $panel-inner-border;
        }
    }

    .panel-xs{
        .panel-heading {
            .panel-title{
                font-size: 13px;
                font-weight: 700;
            }
        }
        .table-alternatives{
            margin: 0;
            > tbody > tr > td{
                text-align: center;
                border: 1px solid #ddd;
                vertical-align: middle;
            }
        }
        .form-horizontal{
            .form-group{
                &:last-child{
                    margin-bottom: 0
                }
            }
        }
    }
}

// Contextual variations
.panel-default {
    @include panel-variant($panel-default-border, $panel-default-text, $panel-default-heading-bg, $panel-default-border);
}

.panel-primary {
    @include panel-variant($panel-primary-border, $panel-primary-text, $panel-primary-heading-bg, $panel-primary-border);
}

.panel-success {
    @include panel-variant($panel-success-border, $panel-success-text, $panel-success-heading-bg, $panel-success-border);
}

.panel-info {
    @include panel-variant($panel-info-border, $panel-info-text, $panel-info-heading-bg, $panel-info-border);
}

.panel-warning {
    @include panel-variant($panel-warning-border, $panel-warning-text, $panel-warning-heading-bg, $panel-warning-border);
}

.panel-danger {
    @include panel-variant($panel-danger-border, $panel-danger-text, $panel-danger-heading-bg, $panel-danger-border);
}

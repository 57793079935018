.categories-container{
    margin-bottom: 30px;
	.category-layer{
		position   : relative;
		margin-top : 30px;
		.content-layer{
			background : rgba(0,0,0,.7);
			position   : absolute;
			top        : 0;
			left       : 0;
			opacity    : 0;
			width      : 100%;
			height     : 100%;
			overflow   : hidden;
			@include scale(.9);
			@include transition(all .25s);
			&:hover{
				opacity: 1;
				@include scale(1);
			}
			.content-center{
				top        : 50%;
				position   : relative;
				text-align : center;
				transition : all .5s;
				padding    : 1em;
			    @include translateY(-50%);
				h3{
					text-align  : center;
					color       : white;
					font-size   : 24px;
					font-weight : 400;
					margin      : 20px 0;
				}
				p{
					text-align  : center;
					color       : white;
					line-height : 24px;
				}
			}
		}
	}
}

.category-layer{
	.category-header{
		text-align    : center;
		margin-bottom : 35px;
		h2{
			font-size: 32px;
			margin: 0 0 20px;
		}
		h4{
			font-size   : 15px;
			font-weight : 400;
			line-height : 24px;
		}
	}
	.category-body{
		.icon-container{
			width      : 60%;
			margin     : 0 auto;
			background : white;
			text-align : center;
			padding    : 20px;
		    @include box-shadow($material-shadow-1dp);
		    @media (max-width: $screen-sm) {
				width         : 100%;
				margin-bottom : 0px
		    }
		    a{
		    	&:hover{
		    		text-decoration: none;
		    	}
		    }
    		img{
				max-width     : 177px;
				margin-bottom : 25px;
				@include transition(all .25s);
    		}
    		h4{
				font-size   : 18px;
				font-weight : 400;
				color       : rgb(0, 0, 0);
				margin      : 0;
			    @include transition(all .25s);
    		}
    		&:hover{
				img{
					@include scale(1.1);
				}
				h4{
					font-weight: 700;
				}
			}
		}
	}
}
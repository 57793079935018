/**
 * Paginación AJAX Handler [Definition]
 */
.render-container{
    margin: 10px 0 40px 0;
    .pagination{
        margin: 40px 0 0 0;
        vertical-align: middle;
    }
}

.request-loading{
	display: none;
	.spinner{
        text-align      : center;
        margin          : 35px auto;
        display         : flex;
        align-items     : center;
        justify-content : center;
        width           : 128px;
        height          : 128px;
        //background      : white;
        //box-shadow      : 0 1px 1px rgba(0,0,0,0.20);
		.clip{

            height        : 64px;
            width         : 64px;
            border-radius : 100%;
            border-width  : 3px;
            border-style  : solid;
            border-color  : #3c50b4 #3c50b4 transparent;
            background    : transparent;
            display: inline-block;
		    -webkit-animation: clipDelay 0.75s 0s infinite linear;
		            animation: clipDelay 0.75s 0s infinite linear;
		    -webkit-animation-fill-mode: both;
		              animation-fill-mode: both;
		}
	}
    .spinner-transparent{
        background: transparent;
        box-shadow: none;
        margin: 50px auto;
    }
}

@-webkit-keyframes clipDelay
{
    0%
    {
        -webkit-transform: rotate(0deg) scale(1);
                transform: rotate(0deg) scale(1);
    }
    50%
    {
        -webkit-transform: rotate(180deg) scale(1);
                transform: rotate(180deg) scale(1);
    }
    100%
    {
        -webkit-transform: rotate(360deg) scale(1);
                transform: rotate(360deg) scale(1);
    }
}

@keyframes clipDelay
{
    0%
    {
        -webkit-transform: rotate(0deg) scale(1);
                transform: rotate(0deg) scale(1);
    }
    50%
    {
        -webkit-transform: rotate(180deg) scale(1);
                transform: rotate(180deg) scale(1);
    }
    100%
    {
        -webkit-transform: rotate(360deg) scale(1);
                transform: rotate(360deg) scale(1);
    }
}
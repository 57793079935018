.search-container{
	.search-header{
	    position: relative;
		h2{
			font-size   : 18px;
			margin      : 0 0 35px;
			line-height : 42px;
		}
	}
	.search-body{
		.media-item{
			&:first-child{
				margin-top: 0;
			}
		}
	}
}

.media-item{
	border        : 1px solid $brand-border;
	margin-top    : 20px;
	border-radius : 2px;
	background    : rgb(255,255,255);
	&:first-child{
		margin-top: 0;
	}
	.media{
		display        : flex;
		padding        : 10px;
		flex-direction : row;
	    .media-object{
    	    width: 64px;
	        object-fit: cover;
    		height: 64px;
	    }
	    .media-heading{
			font-size   : 15px;
			line-height : 24px;
	    }
	    .media-body{
			display         : flex;
			align-items     : flex-start;
			justify-content : center;
			flex-direction  : column;
	        p{
	        	margin: 0;
	        }
	    }

	  	@media (max-width: $screen-xs) {
		    flex-direction: column;
		    justify-content: center;
		    align-items: center;

		    .media-object{
	    	    width: 144px;
	    		height: 144px;
		    }
		    .media-body{
	    	    width: 100%;
			    margin-top: 20px;
			    text-align: center;
		        align-items: center;
		    }
  		}
	}
	.counters{
		display         : flex;
		justify-content : space-around;
		border-top      : 1px solid $brand-border;
		padding         : 10px;
	    .counter-box{
			width      : 50%;
			text-align : center;
	    }
	    .border{
	    	border-right: 1px solid $brand-border;
	    }
	}
	.info-group{
		@media (max-width: $screen-xs) {
			display: block;
			padding: 0;
			.counter-box{
				width : 100%;
				padding: 10px;
			}
			.border{
				border-right: 0;
		    	border-bottom: 1px solid $brand-border;
		    }
		}
	}
}
/**
* Header [_variables]
*/


/**
* Header [_definition]
*/

$navbar-color-placeholder : rgb(197, 202, 233);

.main-header {

    .navbar-form{
        @include box-shadow(none);
        width: 35%;

        @media (max-width: 1110px) {
            width: 35%;
            margin: 15px 0 5px 0;
        }
        @media (max-width: $grid-float-breakpoint-max) {
            width: 100%;
            margin: 15px 0 5px 0;
        }
        .input-group{
            width: 100%;
            .form-control{
                background: rgb(57, 73, 171);
                border: 0;
                @include placeholder($navbar-color-placeholder);
                color:white;
            }
            i{
                color: $navbar-color-placeholder;
            }
        }
    }
    .dropdown-menu:before{
        position      : absolute;
        top           : -10px;
        right         : 15px;
        display       : inline-block;
        border-right  : 10px solid transparent;
        border-bottom : 10px solid white;
        border-left   : 10px solid transparent;
        content       : '';
        @media (max-width: $grid-float-breakpoint-max) {
            display: none;
        }
    }
    .dropdown-new:before{
        right : 28px;
    }
    .dropdown-categories:before{
        right : 35px;
    }
    .btn-flat-container{
        .btn-flat{
            background  : $brand-btn-header !important;
            padding     : 0 20px;
            line-height : 40px;
            margin      : 21px 0 19px;
            &:hover,
            &:active{
                background: darken($brand-btn-header, 4%) !important;
            }
        }
        @media (max-width: $grid-float-breakpoint-max) {
            padding: 5px 15px 15px 15px;
            .btn-flat{
                margin: 0;
            }
        }
    }
}

.dropdown-user {
    position: relative;
    padding: $navbar-padding-vertical 15px $navbar-padding-vertical 70px !important;

    .img-profile{
        width: 42px;
        height: 42px;
        position: absolute;
        top: 20px;
        left: 15px;
        /*
        img{
            display: inline;
            margin: 0 auto;
            height: 100%;
            width: auto;
        }
        */
    }
}

.dropdown-account{
    width: 100%;
    max-width: 218px;
    min-width: 200px;
    .float-right{
        float: right;
        top: 10px;
        position: relative;
    }
    @media (max-width: $grid-float-breakpoint) {
        max-width: 100%;
    }
}
.open:not(.dropup) > .animated {
    backface-visibility: visible !important;
    @include animation(fadeInDown .25s both);
}

@-webkit-keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 15%, 0);
        transform: translate3d(0, 15%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translate3d(0, 15%, 0);
    }
    to {
        opacity: 1;
        transform: none;
    }
}

.menu-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 0px;
    -moz-column-gap: 0px;
    column-gap: 0px;
    @media (max-width: $screen-md) {
        column-count: 2;
        -webkit-webkit-column-count: 2;
    }
    @media (max-width: $screen-sm) {
        column-count: 3;
        -webkit-column-count: 3;
    }
    @media (max-width: $screen-xs) {
        column-count: 2;
        -webkit-webkit-column-count: 2;
    }
    @media (max-width: 480px) {
        column-count: initial;
        -webkit-webkit-column-count: initial;
    }
    li {
        a {
            width: 100%;
            display: inline-block;
            line-height: 42px;
            display: inline-block;
            padding: 0 20px;
            //text-transform: uppercase;
            //font-weight: 500;
            border-right: 1px solid $dropdown-divider-bg;

            @media (max-width: $grid-float-breakpoint) {
                border-right: 1px solid rgba(255,255,255,.2);
            }
        }
    }
}

/**
 * Collection [_Definition]
 */
$card-padding: 10px;
$img-height: 230px;
/**
 * Collection [_Definition]
 */
.card{
    background-color : $panel-bg;
    border-radius    : 3px;
    border           : 1px solid $brand-border;
    @include transition(all 0.25s);
    &:hover{
        border: 1px solid $brand-info;
    }

    .card-header{
        text-align : center;
        padding    : $card-padding;
        .card-img{
            height        : $img-height;
            margin-bottom : $card-padding;
            a{
                height          : $img-height;
                width           : 100%;
                display         : flex;
                flex-direction  : column;
                justify-content : center;
                align-items     : center;
                img{
                    max-width  : 100%;
                    max-height : $img-height;
                }
            }
        }
        .card-title{
            line-height : $line-height-base;
            font-size   : ceil(($font-size-base * 1.125));
            color       : inherit;
            margin      : 0;
        }
    }
    .card-body{
        border-top: 1px solid $brand-border;
        .col-text{
            padding: ($card-padding * 1.5) 0;
        }
    }
    .card-footer{
        border-top : 1px solid $brand-border;
        padding    : ($card-padding * 1.5);
        .alert{
            padding       : 5px 15px;
            margin-bottom : 0;
        }
    }
}

/**
 * Practices [_Definition]
 */

.collection-quiz{
    .collection-body{
        padding: ($card-padding * 1.5) 0;
    }
    .collection-footer{
        .footer-column{
            padding: ($collection-padding * 1.2);
            &:last-child{
                border-left: 0;
            }
        }
        .alert{
            padding       : 5px 15px;
            margin-bottom : 0;
        }
        .text-right{
            text-align: right !important;
        }
    }
}
/*  Elevation - Based on Angular Material
========================================================================== */

$shadow-key-umbra-opacity      : .2 !default;
$shadow-key-penumbra-opacity   : .14 !default;
$shadow-ambient-shadow-opacity : .12 !default;

$material-shadow-1dp:	0 1px 2px rgba(0, 0, 0, $shadow-key-umbra-opacity),
						0 1px 1px rgba(0, 0, 0, $shadow-key-penumbra-opacity) !default;

$material-shadow-2dp: 	0 1px 5px rgba(0, 0, 0, $shadow-key-umbra-opacity),
						0 2px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
						0 3px 1px -2px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;

$material-shadow-3dp: 	0 1px 8px rgba(0, 0, 0, $shadow-key-umbra-opacity),
						0 3px 4px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
						0 3px 3px -2px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;

$material-shadow-4dp: 	0 2px 4px -1px rgba(0, 0, 0, $shadow-key-umbra-opacity),
						0 4px 5px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
						0 1px 10px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;

$material-shadow-5dp: 	0 3px 5px -1px rgba(0, 0, 0, $shadow-key-umbra-opacity),
						0 5px 8px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
						0 1px 14px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;

$material-shadow-6dp: 	0 3px 5px -1px rgba(0, 0, 0, $shadow-key-umbra-opacity),
						0 6px 10px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
						0 1px 18px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;

$material-shadow-7dp: 	0 4px 5px -2px rgba(0, 0, 0, $shadow-key-umbra-opacity),
						0 7px 10px 1px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
						0 2px 16px 1px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;

$material-shadow-8dp: 	0 5px 5px -3px rgba(0, 0, 0, $shadow-key-umbra-opacity),
						0 8px 10px 1px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
						0 3px 14px 2px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;

$material-shadow-9dp: 	0 5px 6px -3px rgba(0, 0, 0, $shadow-key-umbra-opacity),
						0 9px 12px 1px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
						0 3px 16px 2px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;

$material-shadow-10dp:	0 6px 6px -3px rgba(0, 0, 0, $shadow-key-umbra-opacity),
						0 10px 14px 1px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
						0 4px 18px 3px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;

$material-shadow-11dp:	0 6px 7px -4px rgba(0, 0, 0, $shadow-key-umbra-opacity),
						0 11px 15px 1px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
						0 4px 20px 3px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;

$material-shadow-12dp:	0 7px 8px -4px rgba(0, 0, 0, $shadow-key-umbra-opacity),
						0 12px 17px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
						0 5px 22px 4px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;

$material-shadow-13dp:	0 7px 8px -4px rgba(0, 0, 0, $shadow-key-umbra-opacity),
						0 13px 19px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
						0 5px 24px 4px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;

$material-shadow-14dp:	0 7px 9px -4px rgba(0, 0, 0, $shadow-key-umbra-opacity),
						0 14px 21px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
						0 5px 26px 4px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;

$material-shadow-15dp:	0 8px 9px -5px rgba(0, 0, 0, $shadow-key-umbra-opacity),
						0 15px 22px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
						0 6px 28px 5px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;

$material-shadow-16dp:	0 8px 10px -5px rgba(0, 0, 0, $shadow-key-umbra-opacity),
						0 16px 24px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
						0 6px 30px 5px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;

$material-shadow-17dp:	0 8px 11px -5px rgba(0, 0, 0, $shadow-key-umbra-opacity),
						0 17px 26px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
						0 6px 32px 5px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;

$material-shadow-18dp:	0 9px 11px -5px rgba(0, 0, 0, $shadow-key-umbra-opacity),
						0 18px 28px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
						0 7px 34px 6px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;

$material-shadow-19dp:	0 9px 12px -6px rgba(0, 0, 0, $shadow-key-umbra-opacity),
						0 19px 29px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
						0 7px 36px 6px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;

$material-shadow-20dp:	0 10px 13px -6px rgba(0, 0, 0, $shadow-key-umbra-opacity),
						0 20px 31px 3px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
						0 8px 38px 7px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;

$material-shadow-21dp:	0 10px 13px -6px rgba(0, 0, 0, $shadow-key-umbra-opacity),
						0 21px 33px 3px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
						0 8px 40px 7px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;

$material-shadow-22dp:	0 10px 14px -6px rgba(0, 0, 0, $shadow-key-umbra-opacity),
						0 22px 35px 3px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
						0 8px 42px 7px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;

$material-shadow-23dp:	0 11px 14px -7px rgba(0, 0, 0, $shadow-key-umbra-opacity),
						0 23px 36px 3px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
						0 9px 44px 8px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;

$material-shadow-24dp:	0 11px 15px -7px rgba(0, 0, 0, $shadow-key-umbra-opacity),
						0 24px 38px 3px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
						0 9px 46px 8px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;


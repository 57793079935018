/**
 * Login container [_definition]
 */
.panel-login,
.panel-register{

	margin-bottom: 0;

	/**
	 * Vertical align cols [_definition]
	 *
	 * @url: http://jsfiddle.net/nV3Ua/1192/
	 * @url: https://scotch.io/bar-talk/bootstrap-3-tips-and-tricks-you-still-might-not-know
	 */
	.column {
		[class*="col-"]{
	        margin-bottom: 15px;
		}
	}

	.btn-register{
		margin-top : 18px;
		float      : right;
	}
}

.login-quiz-container{
	.quiz-title{
	    margin: 0 0 20px 0;
    	font-size: 18px;
	}
	.card-image{
		box-shadow: none;
		margin: 0 !important;
		border: 1px dotted $table-border-color;
		.card-header{
			.header-img{
			    margin-bottom: 0 !important;
			}
			h1{
				display: none;
			}
		}
		.card-body{

		}
	}
	.info-item{
	    border: 1px dotted $table-border-color;
	    margin-bottom: 15px;
	    padding: 10px;
	}
	.icon-item{
		box-shadow: none;
	    margin: 0;
	    border: 1px dotted $table-border-color;
	    padding: 10px;
	}
}
/*!
 * Bootstrap v3.3.6 (http://getbootstrap.com)
 * Copyright 2011-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
@import "bootstrap/variables";
@import "bootstrap/mixins";

// Vue Material Design
@import "material/variables";

// Reset and dependencies
@import "bootstrap/normalize";
@import "bootstrap/print";
//@import "bootstrap/glyphicons";

// Core CSS
@import "bootstrap/scaffolding";
@import "bootstrap/type";
@import "bootstrap/code";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";

// Components
@import "bootstrap/component-animations";
@import "bootstrap/dropdowns";
@import "bootstrap/button-groups";
@import "bootstrap/input-groups";
@import "bootstrap/navs";
@import "bootstrap/navbar";
@import "bootstrap/breadcrumbs";
@import "bootstrap/pagination";
//@import "bootstrap/pager";
@import "bootstrap/labels";
@import "bootstrap/badges";
@import "bootstrap/jumbotron";
@import "bootstrap/thumbnails";
@import "bootstrap/alerts";
//@import "bootstrap/progress-bars";
@import "bootstrap/media";
@import "bootstrap/list-group";
@import "bootstrap/panels";
//@import "bootstrap/responsive-embed";
@import "bootstrap/responsive-utilities";
@import "bootstrap/wells";
@import "bootstrap/close";
@import "bootstrap/utilities";

// Components w/ JavaScript
@import "bootstrap/modals";
@import "bootstrap/tooltip";
@import "bootstrap/popovers";
@import "bootstrap/carousel";

// Bootwatch
@import "bootwatch/bootwatch";
@import "bootwatch/bootstrapms";

// Main Components
@import "main/animations";
@import "main/header";
@import "main/footer";
@import "main/breadcrumb";
@import "main/buttons";
@import "main/profile";
@import "main/notification";
@import "main/errors";
@import "main/forms";

// Utilities
@import "utils/flex";
@import "utils/groups";
@import "utils/center";
@import "utils/transition";
@import "utils/margin";
@import "utils/loading";
@import "utils/social_buttons";

// Material
@import "material/shadow";

// Fronted
@import "frontend/login";
@import "frontend/homepage";
@import "frontend/collection";
@import "frontend/pagination";

@import "frontend/review_quiz";

@import "frontend/evaluation";
@import "frontend/ranking";
@import "frontend/results";

@import "frontend/account";
@import "frontend/categories";
@import "frontend/search";
@import "frontend/followers";
@import "frontend/select2";

// Admin
@import "backend/me";
@import "backend/collection_form";
@import "backend/collection_index";
@import "backend/collection_show";
@import "backend/quiz_index";
@import "backend/quiz_show";
@import "backend/question_index";

// Plugins
@import "plugins/ezdz";
@import "plugins/primsjs";
@import "plugins/sweetalert";
@import "plugins/charts";
@import "plugins/selectize";
@import "plugins/datatables";
@import "plugins/slick";
@import "plugins/slick-theme";
@import "plugins/datetime";
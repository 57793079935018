// SweetAlert
// 2014-2015 (c) - Tristan Edwards
// github.com/t4t5/sweetalert
body.stop-scrolling {
    height   : 100%;
    overflow : hidden;
}

.sweet-overlay {
    background-color : rgb(0, 0, 0);
    -ms-filter       : "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
    background-color : rgba(black, 0.4);
    position         : fixed;
    left             : 0;
    right            : 0;
    top              : 0;
    bottom           : 0;
    display          : none;
    z-index          : 10000;
}

.sweet-alert {
    $width           : 478px;
    $padding         : 17px;
    background-color : white;
    font-family      : $font-family-sans-serif;
    width            : $width;
    padding          : $padding;
    border-radius    : 5px;
    text-align       : center;
    position         : fixed;
    left             : 50%;
    top              : 50%;
    margin-left      : -($width/2 + $padding);
    margin-top       : -200px;
    overflow         : hidden;
    display          : none;
    z-index          : 99999;
    @media (max-width: $screen-xs) {
        width        : auto;
        margin-left  : 0;
        margin-right : 0;
        left         : 15px;
        right        : 15px;
        top: 40px;
        margin-top: 0 !important;
    }
    h2 {
        color          : rgb(85, 85, 85);
        font-size      : 24px;
        text-align     : center;
        font-weight    : 500;
        text-transform : none;
        position       : relative;
        padding        : 0;
        line-height    : 40px;
        display        : block;
    }
    p {
        color       : rgb(104, 104, 104);
        font-size   : 15px;
        text-align  : center;
        font-weight : 400;
        position    : relative;
        text-align  : inherit;
        float       : none;
        margin      : 0;
        padding     : 0;
        line-height : $line-height-base;
    }
    fieldset {
        border   : none;
        position : relative;
    }
    .sa-error-container {
        background-color : #f1f1f1;
        margin-left      : -17px;
        margin-right     : -17px;
        overflow         : hidden;
        padding          : 0 10px;
        max-height       : 0;
        webkit-transition: padding 0.15s, max-height 0.15s;
        transition: padding 0.15s, max-height 0.15s;
        &.show {
            padding           : 10px 0;
            max-height        : 100px;
            webkit-transition : padding 0.2s, max-height 0.2s;
            transition        : padding 0.25s, max-height 0.25s;
        }
        .icon {
            display          : inline-block;
            width            : 24px;
            height           : 24px;
            border-radius    : 50%;
            background-color : rgb(234, 125, 125);
            color            : white;
            line-height      : 24px;
            text-align       : center;
            margin-right     : 3px;
        }
        p {
            display: inline-block;
        }
    }
    .sa-input-error {
        position                 : absolute;
        top                      : 29px;
        right                    : 26px;
        width                    : 20px;
        height                   : 20px;
        opacity                  : 0;
        -webkit-transform        : scale(0.5);
        transform                : scale(0.5);
        -webkit-transform-origin : 50% 50%;
        transform-origin         : 50% 50%;
        -webkit-transition       : all 0.1s;
        transition               : all 0.1s;
        &::before,
        &::after {
            content          : "";
            width            : 20px;
            height           : 6px;
            background-color : #f06e57;
            border-radius    : 3px;
            position         : absolute;
            top              : 50%;
            margin-top       : -4px;
            left             : 50%;
            margin-left      : -9px;
        }
        &::before {
            -webkit-transform : rotate(-45deg);
            transform         : rotate(-45deg);
        }
        &::after {
            -webkit-transform : rotate(45deg);
            transform         : rotate(45deg);
        }
        &.show {
            opacity: 1;
            -webkit-transform : scale(1);
            transform         : scale(1);
        }
    }
    input {
        width              : 100%;
        box-sizing         : border-box;
        border-radius      : 3px;
        border             : 1px solid rgb(215, 215, 215);
        height             : 43px;
        margin-top         : 10px;
        margin-bottom      : 17px;
        font-size          : 18px;
        box-shadow         : inset 0px 1px 1px rgba(black, 0.06);
        padding            : 0 12px;
        display            : none;
        -webkit-transition : all 0.3s;
        transition         : all 0.3s;
        &:focus {
            outline    : none;
            box-shadow : 0px 0px 3px rgb(196, 230, 245);
            border     : 1px solid rgb(180, 219, 237);
            &::-moz-placeholder {
                transition: opacity 0.3s 0.03s ease;
                opacity: 0.5;
            }
            &:-ms-input-placeholder {
                transition: opacity 0.3s 0.03s ease;
                opacity: 0.5;
            }
            &::-webkit-input-placeholder {
                transition: opacity 0.3s 0.03s ease;
                opacity: 0.5;
            }
        }
        &::-moz-placeholder {
            color: lighten(#575757, 40);
        }
        &:-ms-input-placeholder {
            color: lighten(#575757, 40);
        }
        &::-webkit-input-placeholder {
            color: lighten(#575757, 40);
        }
    }
    &.show-input input {
        display: block;
    }
    .sa-confirm-button-container {
        display  : inline-block;
        position : relative;
    }
    .la-ball-clip-rotate {
        position    : absolute;
        left        : 50%;
        top         : 50%;
        margin-left : -15px;
        opacity     : 0;
        visibility  : hidden;
    }
    button {

        $btnBlue: #8CD4F5;
        $btnGray: rgb(255,255,255);

        background-color : $btnBlue;
        color            : white;
        border           : none;
        box-shadow       : none;
        font-size        : 15px;
        font-weight      : 400;
        border-radius    : 3px;
        padding          : 10px 21px;
        margin           : 25px 5px 0 5px;
        cursor           : pointer;
        @include transition(all .15s);

        &:focus {
            outline    : none;
            box-shadow : 0 0 2px rgba(128, 179, 235, 0.5), inset 0 0 0 1px rgba(0, 0, 0, 0.05);
        }
        &:hover {
            background-color: darken($btnBlue, 5%);
        }
        &:active {
            background-color: darken($btnBlue, 10%);
        }
        &.cancel {
            background-color : $btnGray;
            color            : #333;
            border           :1px solid #ccc;
            &:hover {
                background-color: darken($btnGray, 3%);
            }
            &:active {
                background-color: darken($btnGray, 10%);
            }
            // Cancel button should keep the same style
            &:focus {
                box-shadow: rgba(197, 205, 211, 0.8) 0px 0px 2px, rgba(0, 0, 0, 0.0470588) 0px 0px 0px 1px inset !important;
            }
        }
        &[disabled] {
            opacity: .6;
            cursor: default;
        }
        &.confirm[disabled] {
            color: transparent;
            ~ .la-ball-clip-rotate {
                opacity          : 1;
                visibility       : visible;
                transition-delay : 0s;
            }
        }
        // Removes selection outline in Firefox
        &::-moz-focus-inner {
            border: 0;
        }
    }
    // Only show focus-style when there is multiple choice of actions
    &[data-has-cancel-button=false] button {
        box-shadow: none !important;
    }
    &[data-has-confirm-button=false][data-has-cancel-button=false] {
        padding-bottom: 40px;
    }
    .sa-icon {

        $red    : rgb(241,105,105);
        $orange : #F8BB86;
        $blue   : lighten($brand-info, 4%);
        $green  : #A5DC86;

        width                 : 80px;
        height                : 80px;
        border                : 4px solid gray;
        -webkit-border-radius : 40px;
        border-radius         : 40px;
        border-radius         : 50%;
        margin                : 20px auto;
        padding               : 0;
        position              : relative;
        box-sizing            : content-box;
        &.sa-error {
            border-color: $red;
            .sa-x-mark {
                position : relative;
                display  : block;
            }
            .sa-line {
                position         : absolute;
                height           : 5px;
                width            : 47px;
                background-color : $red;
                display          : block;
                top              : 37px;
                border-radius    : 2px;
                &.sa-left {
                    -webkit-transform : rotate(45deg);
                    transform         : rotate(45deg);
                    left              : 17px;
                }
                &.sa-right {
                    -webkit-transform : rotate(-45deg);
                    transform         : rotate(-45deg);
                    right             : 16px;
                }
            }
        }
        &.sa-warning {
            border-color: $orange;
            .sa-body {
                // Exclamation mark body
                position              : absolute;
                width                 : 5px;
                height                : 47px;
                left                  : 50%;
                top                   : 10px;
                -webkit-border-radius : 2px;
                border-radius         : 2px;
                margin-left           : -2px;
                background-color      : $orange;
            }
            .sa-dot {
                // Exclamation mark dot
                position              : absolute;
                width                 : 7px;
                height                : 7px;
                -webkit-border-radius : 50%;
                border-radius         : 50%;
                margin-left           : -3px;
                left                  : 50%;
                bottom                : 10px;
                background-color      : $orange;
            }
        }
        &.sa-info {
            border-color: $blue;
            &::before {
                // i-letter body
                content          : "";
                position         : absolute;
                width            : 5px;
                height           : 29px;
                left             : 50%;
                bottom           : 17px;
                border-radius    : 2px;
                margin-left      : -2px;
                background-color : $blue;
            }
            &::after {
                // i-letter dot
                content          : "";
                position         : absolute;
                width            : 7px;
                height           : 7px;
                border-radius    : 50%;
                margin-left      : -3px;
                top              : 19px;
                background-color : $blue;
            }
        }
        &.sa-success {
            border-color: $green;
            &::before,
            &::after {
                // Emulate moving circular line
                content               : '';
                -webkit-border-radius : 40px;
                border-radius         : 40px;
                border-radius         : 50%;
                position              : absolute;
                width                 : 60px;
                height                : 120px;
                background            : white;
                -webkit-transform     : rotate(45deg);
                transform             : rotate(45deg);
            }
            &::before {
                -webkit-border-radius    : 120px 0 0 120px;
                border-radius            : 120px 0 0 120px;
                top                      : -7px;
                left                     : -33px;
                -webkit-transform        : rotate(-45deg);
                transform                : rotate(-45deg);
                -webkit-transform-origin : 60px 60px;
                transform-origin         : 60px 60px;
            }
            &::after {
                -webkit-border-radius    : 0 120px 120px 0;
                border-radius            : 0 120px 120px 0;
                top                      : -11px;
                left                     : 30px;
                -webkit-transform        : rotate(-45deg);
                transform                : rotate(-45deg);
                -webkit-transform-origin : 0px 60px;
                transform-origin         : 0px 60px;
            }
            .sa-placeholder {
                // Ring
                width                 : 80px;
                height                : 80px;
                border                : 4px solid rgba($green, 0.2);
                -webkit-border-radius : 40px;
                border-radius         : 40px;
                border-radius         : 50%;
                box-sizing            : content-box;
                position              : absolute;
                left                  : -4px;
                top                   : -4px;
                z-index               : 2;
            }
            .sa-fix {
                // Hide corners left from animation
                width             : 5px;
                height            : 90px;
                background-color  : white;
                position          : absolute;
                left              : 28px;
                top               : 8px;
                z-index           : 1;
                -webkit-transform : rotate(-45deg);
                transform         : rotate(-45deg);
            }
            .sa-line {
                height           : 5px;
                background-color : $green;
                display          : block;
                border-radius    : 2px;
                position         : absolute;
                z-index          : 2;
                &.sa-tip {
                    width             : 25px;
                    left              : 14px;
                    top               : 46px;
                    -webkit-transform : rotate(45deg);
                    transform         : rotate(45deg);
                }
                &.sa-long {
                    width             : 47px;
                    right             : 8px;
                    top               : 38px;
                    -webkit-transform : rotate(-45deg);
                    transform         : rotate(-45deg);
                }
            }
        }
        &.sa-custom {
            background-size     : contain;
            border-radius       : 0;
            border              : none;
            background-position : center center;
            background-repeat   : no-repeat;
        }
    }
}


/*
* Animations
*/

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
    -webkit-animation: #{$str};
    animation: #{$str};
}

// Modal animation
@include keyframes(showSweetAlert) {
    0% {
        transform: scale(0.7);
        -webkit-transform: scale(0.7);
    }
    45% {
        transform: scale(1.05);
        -webkit-transform: scale(1.05);
    }
    80% {
        transform: scale(0.95);
        -webkit-transform: scale(0.95);
    }
    100% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}

@include keyframes(hideSweetAlert) {
    0% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
    100% {
        transform: scale(0.5);
        -webkit-transform: scale(0.5);
    }
}

@include keyframes(slideFromTop) {
    0% {
        top: 0%;
    }
    100% {
        top: 50%;
    }
}

@include keyframes(slideToTop) {
    0% {
        top: 50%;
    }
    100% {
        top: 0%;
    }
}

@include keyframes(slideFromBottom) {
    0% {
        top: 70%;
    }
    100% {
        top: 50%;
    }
}

@include keyframes(slideToBottom) {
    0% {
        top: 50%;
    }
    100% {
        top: 70%;
    }
}

.showSweetAlert {
    &[data-animation=pop] {
        @include animation('showSweetAlert 0.3s');
    }
    &[data-animation=none] {
        @include animation('none');
    }
    &[data-animation=slide-from-top] {
        @include animation('slideFromTop 0.3s');
    }
    &[data-animation=slide-from-bottom] {
        @include animation('slideFromBottom 0.3s');
    }
}

.hideSweetAlert {
    &[data-animation=pop] {
        @include animation('hideSweetAlert 0.2s');
    }
    &[data-animation=none] {
        @include animation('none');
    }
    &[data-animation=slide-from-top] {
        @include animation('slideToTop 0.4s');
    }
    &[data-animation=slide-from-bottom] {
        @include animation('slideToBottom 0.3s');
    }
}

// Success icon animation
@include keyframes(animateSuccessTip) {
    0% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    54% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }
    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }
    100% {
        width: 25px;
        left: 14px;
        top: 45px;
    }
}

@include keyframes(animateSuccessLong) {
    0% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    65% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    84% {
        width: 55px;
        right: 0px;
        top: 35px;
    }
    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}

@include keyframes(rotatePlaceholder) {
    0% {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }
    5% {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }
    12% {
        transform: rotate(-405deg);
        -webkit-transform: rotate(-405deg);
    }
    100% {
        transform: rotate(-405deg);
        -webkit-transform: rotate(-405deg);
    }
}

.animateSuccessTip {
    @include animation('animateSuccessTip 0.75s');
}

.animateSuccessLong {
    @include animation('animateSuccessLong 0.75s');
}

.sa-icon.sa-success.animate::after {
    @include animation('rotatePlaceholder 4.25s ease-in');
}

// Error icon animation
@include keyframes(animateErrorIcon) {
    0% {
        transform: rotateX(100deg);
        -webkit-transform: rotateX(100deg);
        opacity: 0;
    }
    100% {
        transform: rotateX(0deg);
        -webkit-transform: rotateX(0deg);
        opacity: 1;
    }
}

.animateErrorIcon {
    @include animation('animateErrorIcon 0.5s');
}

@include keyframes(animateXMark) {
    0% {
        transform: scale(0.4);
        -webkit-transform: scale(0.4);
        margin-top: 26px;
        opacity: 0;
    }
    50% {
        transform: scale(0.4);
        -webkit-transform: scale(0.4);
        margin-top: 26px;
        opacity: 0;
    }
    80% {
        transform: scale(1.15);
        -webkit-transform: scale(1.15);
        margin-top: -6px;
    }
    100% {
        transform: scale(1);
        -webkit-transform: scale(1);
        margin-top: 0;
        opacity: 1;
    }
}

.animateXMark {
    @include animation('animateXMark 0.5s');
}

@include keyframes(pulseWarning) {
    0% {
        border-color: #F8D486;
    }
    100% {
        border-color: #F8BB86;
    }
}

.pulseWarning {
    @include animation('pulseWarning 0.75s infinite alternate');
}

@include keyframes(pulseWarningIns) {
    0% {
        background-color: #F8D486;
    }
    100% {
        background-color: #F8BB86;
    }
}

.pulseWarningIns {
    @include animation('pulseWarningIns 0.75s infinite alternate');
}

@include keyframes(rotate-loading) {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
.la-ball-clip-rotate,
.la-ball-clip-rotate > div {
    position: relative;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

.la-ball-clip-rotate {
    display: block;
    font-size: 0;
    color: #fff;
}

.la-ball-clip-rotate.la-dark {
    color: #333;
}

.la-ball-clip-rotate > div {
    display: inline-block;
    float: none;
    background-color: currentColor;
    border: 0 solid currentColor;
}

.la-ball-clip-rotate {
    width: 32px;
    height: 32px;
}

.la-ball-clip-rotate > div:first-child {
    width: 24px;
    height: 24px;
    background: transparent;
    border-width: 2px;
    border-bottom-color: transparent;
    border-radius: 100%;
    -webkit-animation: ball-clip-rotate .75s linear infinite;
       -moz-animation: ball-clip-rotate .75s linear infinite;
         -o-animation: ball-clip-rotate .75s linear infinite;
            animation: ball-clip-rotate .75s linear infinite;
}

.la-ball-clip-rotate.la-sm {
    width: 16px;
    height: 16px;
}

.la-ball-clip-rotate.la-sm > div {
    width: 16px;
    height: 16px;
    border-width: 1px;
}

.la-ball-clip-rotate.la-2x {
    width: 64px;
    height: 64px;
}

.la-ball-clip-rotate.la-2x > div {
    width: 64px;
    height: 64px;
    border-width: 4px;
}

.la-ball-clip-rotate.la-3x {
    width: 96px;
    height: 96px;
}

.la-ball-clip-rotate.la-3x > div {
    width: 96px;
    height: 96px;
    border-width: 6px;
}

/*
 * Animation
 */
@-webkit-keyframes ball-clip-rotate {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    50% {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@-moz-keyframes ball-clip-rotate {
    0% {
        -moz-transform: rotate(0deg);
             transform: rotate(0deg);
    }
    50% {
        -moz-transform: rotate(180deg);
             transform: rotate(180deg);
    }
    100% {
        -moz-transform: rotate(360deg);
             transform: rotate(360deg);
    }
}
@-o-keyframes ball-clip-rotate {
    0% {
        -o-transform: rotate(0deg);
           transform: rotate(0deg);
    }
    50% {
        -o-transform: rotate(180deg);
           transform: rotate(180deg);
    }
    100% {
        -o-transform: rotate(360deg);
           transform: rotate(360deg);
    }
}
@keyframes ball-clip-rotate {
    0% {
        -webkit-transform: rotate(0deg);
           -moz-transform: rotate(0deg);
             -o-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    50% {
        -webkit-transform: rotate(180deg);
           -moz-transform: rotate(180deg);
             -o-transform: rotate(180deg);
                transform: rotate(180deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
           -moz-transform: rotate(360deg);
             -o-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
/**
 * Agrupar columnas de 4 en 4 [_Definition]
 */
.group-columns-4{
    div[class*="col-"]{
        .column{
            margin-top: 30px;
        }
        &:nth-child(-n+4){
            .column{
                margin-top: 0;
            }
        }

        @media (max-width: $screen-lg) {

            &:nth-child(-n+3){
                .column{
                    margin-top: 0;
                }
            }
            &:not(:nth-child(-n+3)) {
                .column{
                    margin-top: 30px;
                }
            }
        }

        @media (max-width: $screen-md) {

            &:nth-child(-n+2){
                .column{
                    margin-top: 0;
                }
            }
            &:not(:nth-child(-n+2)) {
                .column{
                    margin-top: 30px;
                }
            }
        }

        @media (max-width: $screen-sm) {

            &:first-child{
                .column{
                    margin-top: 0;
                }
            }
            &:not(:first-child) {
                .column{
                    margin-top: 30px;
                }
            }
        }
    }
}

/**
 * Agrupar columnas de 3 en 3 [_Definition]
 */
.group-columns-3{
    div[class*="col-"]{
        .column{
            margin-top: 30px;
        }
        &:nth-child(-n+3){
            .column{
                margin-top: 0;
            }
        }

        @media (max-width: $screen-lg) {

            &:nth-child(-n+2){
                .column{
                    margin-top: 0;
                }
            }
            &:not(:nth-child(-n+2)) {
                .column{
                    margin-top: 30px;
                }
            }
        }

        @media (max-width: $screen-sm) {

            &:first-child{
                .column{
                    margin-top: 0;
                }
            }
            &:not(:first-child) {
                .column{
                    margin-top: 30px;
                }
            }
        }

    }
}

.group-columns-mobile{
    div[class*="col-"]{
        @media (max-width: $screen-sm) {

            &:nth-child(-n+2){
                .column{
                    margin-top: 0;
                }
            }
            &:not(:nth-child(-n+2)) {
                .column{
                    margin-top: 30px;
                }
            }
        }
        @media (max-width: $screen-xs) {

            &:first-child{
                .column{
                    margin-top: 0;
                }
            }
            &:not(:first-child) {
                .column{
                    margin-top: 30px;
                }
            }
        }
    }
}

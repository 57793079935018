.navigation-section{
	background: white;
    border-bottom: 1px solid $brand-border;
    .nav-tabs{
    	.main-left{
    		float: left;
    	}
    	li{
    		float: none;
		    display: inline-block;
		    &.active{
		    	font-weight: 700;
		    }
		    a{
		    	line-height: 40px;
		    }
    	}
    }
}

.init-container{
    margin: 40px auto;

    .init-header{
        position: relative;
        margin-bottom: 30px;
        h2{
            font-size: 17px;
            line-height: 35px;
            margin: 0;
        }
        .form-search{
            position: absolute;
            top: 8px;
            right: 0;
            width: 300px;
            @media (max-width: $screen-xs) {
                position: relative;
                top: auto;
                right: auto;
                width: 100%;
                margin-top: 10px;
            }
        }
        .init-actions{
            position: absolute;
            top: 0;
            right: 0;
            line-height: 58px;
        }
    }
    .init-body{
        .pagination{
            margin: 40px 0 0 0;
        }
    }
    .init-collections{
        .panel{
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}

.panel-subscription{
    .panel-heading{
        .heading-img{
            display: inline-block;
            max-height: 48px;
            vertical-align: top;
            .img{
                width: 48px;
                height: 48px;
            }
        }
        .heading-owner{
            display: inline-block;
            margin-left: 10px;
            vertical-align: top;
            .panel-title{
                margin-bottom: 2px;
            }
            .owner-info{
                p{
                    margin: 0;
                }
            }
        }
        .heading-counter{
            position: absolute;
            top: 20px;
            right: 20px;
            line-height: 48px;
            font-weight: 700;
        }
        .heading-success{
            position: absolute;
            top: 20px;
            right: 20px;
            line-height: 48px;
            font-weight: 700;
            span{
                padding: 10px;
                border: 1px solid $brand-success;
                color: $brand-success;
            }
        }
        @media (max-width: $screen-xs) {
            text-align: center;
            .heading-counter{
                position: relative;
                top: auto;
                right: auto;
                line-height: inherit;
                margin-top: 10px;
                text-align: center;
            }
            .heading-success{
                position: relative;
                top: auto;
                right: auto;
                line-height: inherit;
                margin-top: 20px;
                text-align: center;
                span{
                    display: inline-block;
                }
            }
        }
    }
}

.row-container{
    margin-top: -20px;
    justify-content: center;
}

.mini-card{
    border: 1px solid $brand-border;
    margin-top: 20px;
    &:hover{
        border: 1px solid $brand-twitter;
        box-shadow: none !important;
    }
    .card-body{
        text-align: center;
        .img-wrapper{
            width: 100%;
            > a{
                width: 144px;
                height: 144px;
                display: inline-block;
                img{
                    width: 100%;
                }
            }
        }
        h3{
            font-size: 14px;
            margin: 15px 0 0;
        }
    }
    .card-footer{
        border-top: 1px solid $brand-border;
        .pull-left{
            width        : 50%;
            border-right : 1px solid $brand-border;
            text-align   : center;
            padding      : 10px 0;
        }
        .pull-right{
            text-align : center;
            width      : 50%;
            padding    : 10px 0;
        }
        .footer-bottom{
            border-top: 1px solid $brand-border;
            padding: 10px;
        }
    }
}

.subscriptions-pagination{
    text-align: center;
    .pagination{
        margin: 40px 0 20px;
    }
}

.fbtn-container{
    .btn{
        background: rgb(255, 23, 68);
        color: white;
        border-radius: 50%;
        bottom: 35px;
        right: 20px;
        height: 56px;
        width: 56px;
        border: 0;
        display: none;
        font-size: 21px;
        position: fixed;
        &:hover,
        &:active{
            @include box-shadow($material-shadow-7dp);
        }
    }
}

.media-container{
    .media{
        .media-image{
            width: 128px;
            height: 128px;
            min-width: 128px;
            position: relative;
            overflow: hidden;
            display: inline-block;
            float: left;
            img{
                display: inline;
                margin: auto;
                position: absolute;
                left: 50%;
                top: 50%;
                height: 100%;
                width: auto;
                -webkit-transform: translate(-50%,-50%);
                  -ms-transform: translate(-50%,-50%);
                      transform: translate(-50%,-50%);
            }
        }
        .media-body{
            margin-left: 10px;
            width: auto;
        }
    }
}

.table-default{
    background: rgb(255,255,255);
    margin: 0;
}
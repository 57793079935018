/**
 * Collection Bar [Definition]
 */
.collection-bar{
	padding    : $line-height-computed 0;
	background : white;
	.collection-owner{
	    width: 100%;
	    > a{
    	    width: 40px;
		    height: 40px;
		    position: relative;
		    overflow: hidden;
		    display: inline-block;
		    img{
	    	    display: inline;
			    margin: 0 auto;
			    height: 100%;
			    width: auto;
		    }
	    }
	    .owner-info{
    	    display: inline-block;
		    vertical-align: top;
		    position: relative;
		    top: -3px;
		    margin-left: 5px;
    		p{
				margin-bottom: 0px;
			}
	    }
	}
	.collection-counters{
		width      : 100%;
		text-align : right;
		.quiz{
			padding-right : 8px;
			border-right  : 2px solid #ddd;
		}
		.followers{
		    margin-left: 10px;
		}
	}
}

/**
 * Collection Suscribe [Definition]
 */
.collection-suscribe{
    text-align: right;
	.input-group{
		.form-control{
			line-height : 35px;
			padding     : 0 10px;
			width       : auto;
			height      : 35px;
			float       : right;
			display     : inline-block;
		}
		.input-group-btn{
			.btn{

			}
		}
	}

    @media (max-width: $screen-xs) {

		text-align : center;
		margin-top : 20px;

    }
}

/**
 * Collection quiz container [Definition]
 */
.quiz-container{
	.pagination{
	    margin: 23px 0 0 0;
	}
}
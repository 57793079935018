/**
 * Card Profile
 */
.card-profile{
    position         : relative;
    background-color : rgb(255,255,255);
    margin-bottom    : 20px;
    .profile-details{
        padding    : 20px;
        text-align : center;
    	.img-profile{
    		max-width: 128px;
    	}
    	h3{
            font-weight : 500;
            font-size   : 18px;
            line-height : 24px;
    	}
    	p{
    		&:last-child{
    			margin-bottom:0;
    		}
    	}
    }
    .profile-counters{
    	.list-group{
    		margin-bottom: 0;
    		.list-group-item{
                border        : 0;
                border-top    : 1px solid $brand-border;
                border-bottom : 1px solid $brand-border;
    		}
    	}
    }
    .profile-followers{
    	text-align: center;
    	.counter{
            display   : inline-block;
            margin    : 20px;
            font-size : 18px;
    		> span{
                font-weight : normal;
                display     :block;
                font-size   : 13px;
    		}
    	}
    }
}
/**
 * Card Profile
 */
.postcard{
    position         : relative;
    background-color : rgb(255,255,255);
    margin-bottom    : 20px;
    .postcard-body{
        padding: 20px;
        .nav-tabs{
            li{
                a{
                    padding: 10px 20px;
                }
            }
        }
        .tab-content{
            margin-top: 20px;
            .tab-pane{

            }
        }
    }
}

/**
 * Card Profile
 */
.profile-collections{
    .collection-columns{
        .collection{
            border-radius : 3px;
            border        : 1px solid $brand-border;
            @include box-shadow(none);
            &:hover{
               border: 1px solid $brand-info;
            }
            .alert-container{
                span{
                    padding: 12px;
                    &:last-child{
                        line-height: 32px;
                    }
                }
                .alert{
                    padding : 5px 15px;
                    margin  : 0;
                }
            }
        }
    }
}

.profile-pagination{
    margin     : 20px 0;
    text-align : right;
    .form-information{
        width: 100%;
        h4{
            font-size   : 14px;
            font-weight : 500;
            float       : left;
            display     : inline-block;
        }
        .btn-pagination{
            display     : inline-block;
            margin-left : 20px;
        }
    }
}
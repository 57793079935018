// Typography

// [converter] $parent hack
@mixin text-emphasis-variant($parent, $color) {
  #{$parent} {
    color: $color !important;
  }
  a#{$parent}:hover,
  a#{$parent}:focus {
    color: darken($color, 10%) !important;
  }
}

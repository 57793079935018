/**
 * Chart style [Definition]
 */
.chart-success{
	color: $chart-success;
}
.chart-info{
	color: $chart-info;
}
.chart-error{
	color: $chart-error;
}
.chart-warning{
	color: $chart-warning;
}
.chart-primary{
	color: $chart-primary;
}
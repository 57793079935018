/**
 * Footer [Definition]
 */
.main-footer{

	background: white;
	border-top: 1px solid rgba(0,0,0,.1);

	.row {
        margin: $navbar-height 0;
	}

	h2.logo{
		margin        : 0;
		padding       : 0;
		line-height   : 24px;
		margin-bottom : 20px;
	   	a{
				font-size: 24px;
				text-align: center;
				color: $brand-primary;
				img{
				    display: inline-block;
		    		width: 100px;
				}
	   }
	}

	h5 {
		font-size      : 18px;
		font-weight    : bold;
		margin         : 0 0 30px 0;
		display        : inline-block;
		position       : relative;
		text-transform : uppercase;
	    &:before{
			position   : absolute;
			bottom     : -7px;
			left       : 0;
			height     : 2px;
			width      : 100%;
			content    : '';
			background : black;
		}
	}

	h6{
		margin      : 0;
		line-height : 24px;
	}

	ul {
		list-style-type : none;
		padding-left    : 0;
		margin          : 0;
	    li{
	    	line-height: 24px;
	    	a{
				color           : rgb(80, 80, 80);
				text-decoration : none;
				&:hover,
				&:focus {
				    text-decoration: none;
				}
	    	}
	    }
	}

	.social-networks {
	    text-align: center;
	    a{
			font-size : 32px;
			padding   : 10px;
		    @include transition(all .15s);
		    &:hover{
		    	text-decoration: none;
		    }
	    }
	}
	.btn {
		color         : white;
		border-radius : 20px;
		border        : none;
		width         : 150px;
		display       : block;
		margin        : 0 auto;
		margin-top    : 10px;
		line-height   : 25px;
		outline       : none;
		&:hover{
	    	@include box-shadow($material-shadow-5dp);
	    }
	}
	.footer-copyright {
		padding    : ($navbar-height / 4) 0;
		text-align : center;
		background : darken($brand-primary, 5%);
		color      : white;
	    p{
	    	margin: 0;
	    }
	}

	@media screen and (max-width: $screen-md) {
		.social-networks {
		    margin: ($navbar-height / 2) 0 0;
		}
	}

	@media screen and (max-width: $screen-sm) {
        text-align: center;

        h2{
			margin: 0 0 ($navbar-height / 2);
		}

		h5 {
			margin: 0 0 ($navbar-height / 2);
		}

		ul {
		    margin: 0 0 ($navbar-height / 2);
		    li{
		    	line-height: 32px;
		    }
		}

		.social-networks {
			text-align : center;
			margin     : 0 0 ($navbar-height / 2);
		}
    }

}

/**
 * Social Colors [Definition]
 */
.facebook {
    color: $brand-facebook;
    &:hover,
    &:active{
    	color: darken($brand-facebook, 4%);
    }
}

.twitter {
    color: $brand-twitter;
    &:hover,
    &:active{
    	color: darken($brand-twitter, 4%);
    }
}

.google {
    color: $brand-google;
    &:hover,
    &:active{
    	color: darken($brand-google, 4%);
    }
}
/**
 * Row flex [_definition]
 *
 * @url: http://www.bootply.com/126437
 * @url: http://www.bootply.com/IwBoyELqpx
 */
.row-flex{
    display        : flex;
    flex-wrap      : wrap;
    flex-direction : row;

    > div[class*='col-']{
        display: flex;
    }

    .column{
        display       : flex;
        flex          : 1 100%;
        flex-flow     : column nowrap;
        .collection, .card{
            display   : flex;
            flex      : 1 100%;
            flex-flow : column nowrap;
            .collection-header, .card-header{
                align-items     : center;
                justify-content : space-around;
                display         : flex;
                flex            : 1 100%;
                flex-flow       : column nowrap;
            }
        }
    }

    .column-valign{
        display     : flex;
        align-items : center;
    }
}

.vertical-align{
    display: flex;
    flex-direction: row;

    > [class^="col-"],
    > [class*=" col-"] {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
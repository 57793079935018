.btn-elevation{
    padding: 0 30px;
    font-size: 13px;
    line-height: 45px;
    border-radius: 2px;
    text-transform: uppercase;
    @include box-shadow($material-shadow-2dp);
    &:hover,
	&:active{
    	@include box-shadow($material-shadow-7dp);
    }
}

$flat-success: rgb(0,150,135);
$flat-purple: rgb(103,58,183);

.btn-flat-success{
    color: $flat-success;
    border: 1px solid $flat-success;
}

.btn-flat-purple{
    color: $flat-purple;
    border: 1px solid $flat-purple;
}

.btn-weight{
    font-weight: 500 !important;
}
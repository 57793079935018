/**
 * Panel collections [Definition]
 */
.panel-account{
	.pagination-information{
		position : absolute;
		top      : 20px;
		right    : 20px;
		@media (max-width: $screen-xs) {
			position: initial;
		}
		h6{
			font-size   : 13px;
			line-height : $line-height-computed;
			font-weight : 400;
			margin      : 0;
		}
	}
	.spinner{
		//box-shadow: none;
	}

	.profile-collections-wrapper{
		margin-top: -30px;
		.collection-card{
		    margin-top: 30px;
		    box-shadow : none;
			border     : 1px solid $brand-border;
		    @include transition(all .15s);
		    &:hover{
		    	box-shadow : none;
				border     : 1px solid lighten($brand-primary, 25%);
		    }
		    .body-card{
				.card-image{
					position: relative;
					.image-container{
						a{
							img{
								width: 96px;
								height: 96px;
							    min-width: 96px;
							}
						}
					}
				}
				.card-content{
					padding: 5px 20px;
					h3{
					}
				}
			}
		}
	    .pagination{
		    margin: 20px 0 0 0;
		}
	}

}